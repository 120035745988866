import React, { useEffect, useState } from 'react'
import CenteredLoadingSpinner from './Loader'
import { GlobalStateProvider, useGlobalState } from './Context'
import config from './config'
import Button from 'react-bootstrap/Button'

import dashboardIcon from './img/icons/dashboard.png'
import modelIcon from './img/icons/model.png'
import addIcon from './img/icons/add.png'
import chartIcon from './img/icons/chart.png'
import billingIcon from './img/icons/credit_card.png'
import dataIcon from './img/icons/data.png'
import errorIcon from './img/icons/error.png'
import emailIcon from './img/icons/mail.png'
import deleteIcon from './img/icons/remove.png'
import supportIcon from './img/icons/support.png'
import userIcon from './img/icons/user.png'
import logoutIcon from './img/icons/logout.png'


const Documents = () => {
	const [state, dispatch] = useGlobalState()
	const [isLoading, setIsLoading] = useState(true)
	// I want to display documents in a table list form here - just a bunch of rows with the title, the vendor, maybe a preview link. 
	// Maybe at some point I can add a ticker to show how many times this document's model has been used / how many documents of this model are stored.

	useEffect(() => {
		const getData = async () => {
			const isLoaded = Object.keys(state.ui_data).filter((key) => key == 'documents').length > 0
			if (isLoaded) {
				setIsLoading(false)
			} else {
				await fetch(`${config.API_URL}/${state.user.user_id}/documents`).then(
					async (d) => {
						const data = await d.json()
						console.log('dispatch 6')
						await dispatch({
							ui_data: {
								...state.ui_data,
								documents: data,
							}
						})
						await setIsLoading(false)
					}
				)
			}
		}

		getData()
	}, [])

	if (isLoading) {
		return <CenteredLoadingSpinner />
	}

	const data = state.ui_data.documents
	const { documents, current_size, count } = data
	// might need to figure out what count is going to show, otherwise just use documents.length
	// count should show total number of documents stored, each document that comes back in the array should show number of stored items 
	// (remember, these are pdfs that are sent to the service to be parsed, not new training models or anything)

	// create a list of all stored items, show each individually and what type they are / what vendor they're for,
	// show an indicator if they're the "training model"
	// maybe add an indicator to the documents that are used for training models

	const list = documents.map((item, index) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px #aaa solid', paddingLeft: '5px', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', margin: 'auto', alignItems: 'left' }} key={`document_name_${index}`}>{item.title}</div>
				<div style={{ display: 'flex', margin: 'auto', alignItems: 'right' }} key={`document_vendor_${index}`}>{item.vendor_name}</div>
				<div style={{ display: 'flex', margin: 'auto', alignItems: 'right' }} key={`document_input_${index}`}>{item.intake_params}</div>
				<div style={{ display: 'flex', margin: 'auto', alignItems: 'right' }} key={`document_sender_${index}`}>{item.intake_sender}</div>
				<div style={{ display: 'flex', margin: 'auto', alignItems: 'right' }} key={`document_offset_${index}`}></div>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: '10px' }} key={`document_preview_${index}`}>
						<Button variant='outline-secondary'>Preview</Button>
					</div>
					<div key={`document_delete_${index}`}>
						<Button variant='outline-danger'>Delete</Button>
					</div>
				</div>
			</div>
		)
	})

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
				<div style={{ display: 'flex', flexDirection: 'row', padding: '15px', justifyContent: 'space-between' }}>
					<h3>Document Training Models</h3>
					<div style={{ display: 'flex', alignItems: 'center' }}>{count} items in storage</div>
				</div>
				<div className={'usage_container'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontSize: '10pt' }}>
					<div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px #aaa solid', padding: '10px', justifyContent: 'space-between', fontSize: '12pt', fontWeight: 500 }}>
						<div style={{ display: 'flex', alignItems: 'center' }} key={`document_name_header`}>Model ID</div>
						<div style={{ display: 'flex', alignItems: 'center' }} key={`document_vendor_header`}>Vendor</div>
						<div style={{ display: 'flex', alignItems: 'center' }} key={`document_intake_params_header`}>Input</div>
						<div style={{ display: 'flex', alignItems: 'center' }} key={`document_intake_sender_header`}>Sender</div>
						<div style={{ display: 'flex' }}>
							<div style={{ marginRight: '10px' }} key={`document_preview_header`}>
								Preview
							</div>
							<div key={`document_delete_header`}>
								Delete?
							</div>
						</div>
					</div>
					{list}
				</div>
			</div>
		</div>
	)
}

export default Documents