import React, { useState, useEffect, useRef } from 'react'

import parsley_1 from './img/parsley_1.png'

import {
	useNavigate,
} from 'react-router-dom'

import { GlobalStateProvider, useGlobalState } from './Context'

import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import config from './config'
import CenteredLoadingSpinner from './Loader'

const TopNav = () => {
	const [state, dispatch] = useGlobalState()
	const navigate = useNavigate()
	// create auto-sign in thing
	useEffect(() => {
		const signIn = async () => {
			const refresh_token = localStorage.getItem('refresh_token')

			if (!refresh_token) {
				return
			}

			const options = {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					grant_type: 'refresh_token',
					refresh_token
				})
			}
			const token_obj = await fetch(`${config.FIREBASE_REFRESH_URL}?key=${config.FIREBASE_API_KEY}`, options).then(async (pre_json_user) => await pre_json_user.json()).then(async (pre_auth_user) => {
				// console.log(pre_auth_user)
				// console.log("sign in then")
				const user_options = {
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
						// 'Authorization': `Bearer ${pre_auth_user.access_token}`
						'Authorization': `Bearer ${pre_auth_user.id_token}` // pass id_token so that we can use verifyIdToken in the userHandler
					}
				}

				// console.log(JSON.parse(JSON.stringify(pre_auth_user)))

				await fetch(`${config.API_URL}/get-user`, user_options).then(async (aws_user) => {
					const { user } = await aws_user.json()
					user.refresh_token = pre_auth_user.refresh_token
					user.id_token = pre_auth_user.id_token
					user.custom_token = pre_auth_user.access_token
					// console.log('dispatching this user: ', user)
					console.log('dispatch 10')
					await dispatch({ ...state, user })
				}).catch((e) => {
					console.log("second then error")
					console.log(e)
				})


				// localStorage.setItem('custom_token', custom_token)
				// await dispatch({...state, user: {
				// 	...state.user,
				// 	custom_token,
				// 	last_refreshed: Date.now()
				// }})
			}).catch((e) => {
				console.log('asdfasdfasdf')
				console.log(e)
			})

		}
		if(!state.user || state.user == 'isLoading'){
			signIn()
		}
	}, [])

	const signOut = () => {
		firebase.auth().signOut().then(() => {
			localStorage.removeItem('refresh_token')
			console.log('dispatch 11')
			dispatch({ ...state, user: false })
		})
	}

	return (
		<>
			<Navbar className={'custom_navbar'} bg="dark" expand="sm" variant="dark">
				<Navbar.Brand onClick={() => (state.user && state.user != 'isLoading') ? navigate('/dashboard') : navigate('/')} style={{ cursor: 'pointer', paddingLeft: '30px', backgroundImage: `url(${parsley_1})`, backgroundSize: '30px 30px', backgroundPosition: 'center left', backgroundRepeat: 'no-repeat' }}><div style={{ paddingLeft: '15px' }}>PDF Parsley</div></Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto" style={{ display: 'flex', justifyContent: 'space-between' }}>
						{/* {state.screen && <Nav.Link style={{textTransform: 'capitalize'}}>{state.screen.title}</Nav.Link>} */}
						{false && state.user && state.user != 'isLoading' && (
							<>
								<Nav.Link onClick={() => navigate("/dashboard")}>Dashboard</Nav.Link>
								{state.user.user_id.indexOf('jlQzgvbalrZde19zxWW44DcioTd2') !== -1 && <Nav.Link onClick={() => navigate("/create-model")}>Add New Document</Nav.Link>}
							</>
						)}
						{(!state.user || state.user == 'isLoading') && (
							<>
								<NavDropdown title="Explore" id="basic-nav-dropdown">
									<NavDropdown.Item href="#extract">Extract & Analyze</NavDropdown.Item>
									<NavDropdown.Item href="#transform">Transform & Send</NavDropdown.Item>
									<NavDropdown.Item href="#pricing">Pricing</NavDropdown.Item>
								</NavDropdown>
								<Nav.Link href="/sign-up">Get Started</Nav.Link>
							</>
						)}

						<div style={{ flexGrow: 1 }}>{/* button offset buffer */}</div>

						{state.user && state.user != 'isLoading' ?
							(<NavDropdown align="end" title={state.user.first_name} id="basic-nav-dropdown">
								<NavDropdown.Item onClick={() => navigate("/account")}>Account</NavDropdown.Item>
								<NavDropdown.Item href="#">Thing here</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item onClick={() => signOut()}>Sign Out</NavDropdown.Item>
							</NavDropdown>
							) : (
								<>
									<Nav.Link href="/sign-in">Sign In</Nav.Link>
								</>
							)
						}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	)
}

export default TopNav