import React, { useRef, useEffect, useState, useContext } from 'react'
import { pdfjs } from "react-pdf"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

import './App.css'
import FileUploader from './FileUploader'

import { inspect } from 'util'

import pdf_file from './test.pdf'
import { GlobalStateProvider, useGlobalState } from './Context'

import PropertyContainer from './PropertyContainer'
// import fs from 'fs'
// import * as PDFParser from "pdf2json"

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const config = { "Width": 38.25, "Height": 49.5 }// overwrite this with page units from pdf2json, since that's what'll be doing the parsing in the backend

const PDFControls = () => {
	const [state, dispatch] = useGlobalState()

	const zoomIn = async () => {
		console.log('dispatch 17')
		await dispatch({ ...state, document: { ...state.document, scale: state.document.scale + 0.2 } })
	}
	const zoomOut = async () => {
		console.log('dispatch 18')
		await dispatch({ ...state, document: { ...state.document, scale: state.document.scale - 0.2 } })
	}
	const resetZoom = async () => {
		console.log('dispatch 19')
		await dispatch({ ...state, document: { ...state.document, scale: 1.0 } })
	}


	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', position: 'sticky', top: '0px', left: '0px', background: '#ddd', zIndex: 11 }}>
				<button className={'square_button'} onClick={() => zoomIn()}>+</button>
				<button className={'square_button'} onClick={() => zoomOut()}>-</button>
				<button className={'square_button'} onClick={() => resetZoom()} style={{ fontSize: '10px', textAlign: 'center' }}>Auto</button>
			</div>
		</>
	)
}

const Preview = ({ url }) => {
	// const [instance, update] = usePDF({ document });
	const bounding = useRef()
	const [state, dispatch] = useGlobalState()
	const [isClicked, setIsClicked] = useState(false)
	const [bounds, setBounds] = useState([0, 0])
	const [pdfDetails, setPdfDetails] = useState({ width: 0, height: 0, scale: 1 })
	const [renderer, setRender] = useState({ width: state.document.width, height: state.document.height, scale: state.document.scale })
	const [coords, setCoords] = useState([0, 0])
	const [selectedCoords, setSelectedCoords] = useState([0, 0])
	const [selectedValue, setSelectedValue] = useState("")

	const [isEditing, setIsEditing] = useState(null)

	const [boundingBox, setBoundingBox] = useState({ startX: 0, startY: 0, diffX: 0, diffY: 0 }) // contains the info needed to pull data from the pdf, using pdf coordinates
	const [boundingRect, setBoundingRect] = useState({ startX: 0, startY: 0, diffX: 0, diffY: 0 }) // contains the info needed to draw the red rectangle on screen (pixel data)
	const [showBoundingRect, setShowBoundingRect] = useState(false)
	const [selectBoundingRect, setSelectBoundingRect] = useState(false)

	// const [isSelectingText, setIsSelectingText] = useState(false)

	const [showText, setShowText] = useState(false)

	// will need to move this - initial implementation was done with state, current implementation listens for local state changes and applies them to context, 
	// final implementation needs to just apply changes to context
	const [boundingRectList, setBoundingRectList] = useState([])

	const setPath = (object, path, value) => path.split('.').reduce((o, p, i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)

	useEffect(() => {
		const Scale = async () => {
			console.log('scaling')
			const { width = 0, height = 0, scale = 1 } = state.document
			// const { width, height } = renderer

			const newWidth = width * scale
			const newHeight = height * scale

			console.log(`width:  ${width} -> ${newWidth}`)
			console.log(`height:  ${height} -> ${newHeight}`)
			console.log(`scale:  ${scale} -> ${state.document.scale}`)

			if (width == 0 || height == 0) {
				console.log('no width or height')
				return
			} else {
				await setRender({ width: newWidth, height: newHeight, scale })
			}
			console.log('scaling end')
		}
		Scale()

		// }, [])
	}, [state.document.scale])


	// need to add logic here for showing different rules
	const mouseDown = (e) => {
		if (state.data.isSelectingRule !== false) {

			const ruleType = state.data.rules[state.data.isSelectingRule].type
			setIsClicked(true)
			const show = state.data.isSelectingRule !== false
			if (ruleType == 'custom') {
				setShowBoundingRect(show)
				setSelectBoundingRect(show) // enable drawing the current draggable selection box

				setSelectedValue(null)

				const set = { startX: coords[0], startY: coords[1], diffX: 0, diffY: 0 }
				console.log('mousedown set', set)
				setBoundingRect(set)
				setBoundingBox({ ...boundingBox, startX: coords[0], startY: coords[1] })
				if (state.data.isSelectingRule !== false) {
					bounding.current.style.cursor = 'crosshair'
				}
			}
			if (ruleType == 'text_between') {
				setSelectedValue(null)
			}
			// if (ruleType == 'text_between') {
			//     setIsSelectingText(true)
			// }
		}

	}

	const mouseUp = async (e) => {
		console.log(state)
		setIsClicked(false)
		bounding.current.style.cursor = 'default'
		const { width, height, scale } = renderer

		// setShowBoundingRect(false)
		// if (!state.isSelecting) {
		if (state.data.isSelectingRule === false) {
			console.log("isSelectingFalse")
			setShowBoundingRect(false)
			setSelectBoundingRect(false)
			setBoundingRect({ startX: 0, startY: 0, diffX: 0, diffY: 0 })
			setBoundingBox({ ...boundingBox, startX: 0, startY: 0 })
			return
		}

		// console.log(state.page)
		// const page = state.page
		// const matches = page.Texts.filter((text, index) => {
		//     let withinX = text.x > boundingRect.startX && text.x < boundingRect.startX + boundingRect.diffX
		//     let withinY = text.y > boundingRect.startY && text.y < boundingRect.startY + boundingRect.diffY

		//     return withinX && withinY
		// }).map((match) => {
		//     return match.R[0].T
		// })

		// console.log(matches)


		// original, works on scale: 1 but not other scales
		const offsetX = e.target.parentNode.parentNode.getBoundingClientRect().left
		const offsetY = e.target.parentNode.parentNode.getBoundingClientRect().top

		// const offsetX = e.target.parentNode.parentNode.getBoundingClientRect().left
		// const offsetY = e.target.parentNode.parentNode.getBoundingClientRect().top

		const diffX = (e.clientX - offsetX) - boundingRect.startX
		const diffY = (e.clientY - offsetY) - boundingRect.startY

		/*
			startX: rule.x / scale,
			startY: rule.y / scale,
			diffX: rule.width / scale,
			diffY: rule.height / scale,
		*/

		// Need a way to translate this to canvas rendering.
		// Currently drawing divs, shouldn't be doing that because page resize fucks things up

		// turns out rendering on the canvas is fucking ridiculous. The page can't be re-rendered with strokes on top of it, 
		// so what happens is the pdf render image just gets cluttered. I think I can work something out with the scale now that
		// document properties are stored in a context
		await setBoundingRect({ startX: boundingRect.startX, startY: boundingRect.startY, diffX, diffY })
		await setSelectBoundingRect({ ...boundingBox, diffX, diffY })

		console.log("selectedValue", selectedValue)

		if (state.data.isSelectingRule !== false) {
			const ruleType = state.data.rules[state.data.isSelectingRule].type
			console.log('checking rule type: ', ruleType)
			if (ruleType == 'custom') {
				let texts = []
				if (state.texts) {
					texts = state.texts.filter((item, index) => {
						const text = { x: item.transform[4], y: state.document.height - item.transform[5] }
						let withinX = text.x > boundingRect.startX / scale && text.x < (boundingRect.startX + boundingRect.diffX) / scale

						let withinY = (text.y > boundingRect.startY / scale && text.y < (boundingRect.startY + boundingRect.diffY) / scale) || (text.y < boundingRect.startY / scale && text.y > (boundingRect.startY + boundingRect.diffY) / scale)

						return withinX && withinY
					}).map((item) => item.str)
					console.log(texts)
				} else {
					console.log("NO TEXTS")
				}

				let newRules = [...state.data.rules]
				const index = state.data.isSelectingRule
				newRules[index] = {
					...newRules[index],
					value: texts.filter((item) => item !== ' ').join(' '),
					x: (boundingBox.startX / scale).toFixed(3),
					y: (boundingBox.startY / scale).toFixed(3),
					width: (diffX / scale).toFixed(3),
					height: (diffY / scale).toFixed(3)
				}

				const boundingRectList = newRules.filter((item => item.type == 'custom')).map((rule) => {
					return {
						startX: rule.x,
						startY: rule.y,
						diffX: rule.width,
						diffY: rule.height,
						id: rule.key,
						current_value: rule.value
					}
				})
				let newState = {
					...state,
					document: {
						...state.document,
						boundingRectList
					},
					data: {
						...state.data,
						isSelectingRule: false,
						rules: newRules
					},
				}
				newState.data.isSelectingRule = false
				console.log('dispatch 20')

				await dispatch({ ...newState })

				await setShowBoundingRect(false)
				await setSelectBoundingRect(false)
				await setBoundingRect({ startX: 0, startY: 0, diffX: 0, diffY: 0 })
				await setBoundingBox({ startX: 0, startY: 0, diffX: 0, diffY: 0 })
			}
			// if(ruleType == 'text_between'){
			//     let texts = []
			//     if (state.texts) {
			//         texts = state.texts.filter((item, index) => {
			//             const text = { x: item.transform[4], y: state.document.height - item.transform[5] }
			//             let withinX = text.x < boundingRect.startX / scale && text.x > (boundingRect.startX + boundingRect.diffX) / scale

			//             let withinY = (text.y > boundingRect.startY / scale && text.y < (boundingRect.startY + boundingRect.diffY) / scale) || (text.y < boundingRect.startY / scale && text.y > (boundingRect.startY + boundingRect.diffY) / scale)

			//             return withinX && withinY
			//         }).map((item) => item.str)
			//         console.log(texts)
			//     } else {
			//         console.log("NO TEXTS")
			//     }
			// }

		}
	}

	useEffect(() => {
		const loadBounds = async () => {
			await setBounds([
				bounding.current.getBoundingClientRect().left,
				bounding.current.getBoundingClientRect().top
			])
		}

		loadBounds()
	}, [])

	const handleMouseOver = async (e, setCoords, bounds, renderer) => {
		const { clientX, clientY } = e
		const { width, height, scale } = state.document

		// full container
		const offsetX = e.target.parentNode.parentNode.getBoundingClientRect().left
		const offsetY = e.target.parentNode.parentNode.getBoundingClientRect().top

		// const offsetX = 0
		// const offsetY = 0
		// const offsetX = e.target.getBoundingClientRect().left
		// const pdfOffsetX = e.target.getBoundingClientRect().left
		// console.log(offsetX, clientX, clientX - offsetX, bounds)
		// const offsetY = e.target.getBoundingClientRect().top
		// const pdfOffsetY = e.target.getBoundingClientRect().top

		const xCoord = clientX - offsetX
		const yCoord = clientY - offsetY
		// const xCoord = config.Width * (((clientX - offsetX)) / width)
		// const yCoord = config.Height * (((clientY - offsetY)) / height)

		// const diffX = e.clientX - boundingRect.startX 
		// const diffY = e.clientY - boundingRect.startY
		const diffX = (e.pageX - offsetX) - boundingRect.startX
		const diffY = (e.pageY - offsetY) - boundingRect.startY

		// Need a way to translate this to canvas rendering.
		// Currently drawing divs, shouldn't be doing that because page resize fucks things up

		// turns out rendering on the canvas is fucking ridiculous. The page can't be re-rendered with strokes on top of it, 
		// so what happens is the pdf render image just gets cluttered. I think I can work something out with the scale now that
		// document properties are stored in a context
		if (selectBoundingRect) {
			setBoundingRect({ startX: boundingRect.startX, startY: boundingRect.startY, diffX, diffY })
			setSelectBoundingRect({ ...boundingBox, diffX, diffY })
		}

		if (state.data.isSelectingRule !== false && isClicked) {
			let texts = []
			if (state.texts) {
				texts = state.texts.filter((item, index) => {
					const text = { x: item.transform[4], y: state.document.height - item.transform[5] }
					let withinX = text.x > boundingRect.startX / scale && text.x < (boundingRect.startX + diffX) / scale
					let withinY = (text.y > boundingRect.startY / scale && text.y < (boundingRect.startY + diffY) / scale) || (text.y < boundingRect.startY / scale && text.y > (boundingRect.startY + diffY) / scale)

					return withinX && withinY
				}).map((item) => item.str)
			}


			let newRules = [...state.data.rules]
			const index = state.data.isSelectingRule
			newRules[index] = {
				...newRules[index],
				value: texts.filter((item) => item !== ' ').join(' '),
				x: (boundingBox.startX / scale).toFixed(3),
				y: (boundingBox.startY / scale).toFixed(3),
				width: (diffX / scale).toFixed(3),
				height: (diffY / scale).toFixed(3)
			}

			const boundingRectList = newRules.map((rule) => {
				return {
					startX: rule.x,
					startY: rule.y,
					diffX: rule.width,
					diffY: rule.height,
					id: rule.key,
					current_value: rule.value
				}
			})
			let newState = {
				...state,
				document: {
					...state.document,
					boundingRectList
				},
				data: {
					...state.data,
					// isSelectingRule: false,
					rules: newRules
				},
			}
			// newState.data.isSelectingRule = false
			console.log('dispatch 34')
			await dispatch({ ...newState })
		}

		setCoords([xCoord, yCoord])
	}

	const handlePDFLoad = async (pdf, setRender) => {
		// const t = Date.now()
		// console.log("handlePDFLoad start " + t)

		// console.log('pdf')
		// console.log(pdf)

		const page = await pdf.getPage(1)
		const width = Math.round(page.view[2])
		const height = Math.round(page.view[3])

		console.log(width, height)

		const newState = {
			...state,
			document: {
				boundingRectList: [],
				width,
				height,
				scale: 1.0
			}
		}
		// await setRender({ width, height })
		console.log('dispatch 21')
		await dispatch({ ...newState })

		console.log('done')
	}

	const DrawBoundingRect = (rect, index = false) => {
		const rect_key = rect.id
		const rect_val = rect.current_value
		// const [state, dispatch] = useGlobalState()
		// const [hovered, setHovered] = useState(false)

		let { diffX, diffY, startX, startY } = rect
		// need to figure out a way to apply scale from center

		let { width, height, scale } = state.document
		// let { width, height, scale } = renderer

		if (index === false) {
			scale = 1
		}

		let percentX, percentY, percentWidth, percentHeight

		// console.log('-------------------------')
		// console.log(startX, width * scale, startY, height * scale, scale)
		// console.log('-------------------------')


		if (index !== false) {
			if (diffX <= 0 && diffY <= 0) {
				percentX = `${((((startX * scale) - Math.abs(diffX * scale)) / (width * scale))) * 100}%`
				percentY = `${((((startY * scale) - Math.abs(diffY * scale)) / (height * scale))) * 100}%`
				percentWidth = `${((Math.abs(diffX * scale) / (width * scale))) * 100}%`
				percentHeight = `${((Math.abs(diffY * scale) / (height * scale))) * 100}%`
			}
			if (diffX <= 0 && diffY > 0) {
				percentX = `${((((startX * scale) - Math.abs(diffX * scale)) / (width * scale))) * 100}%`
				percentY = `${(((startY * scale) / (height * scale))) * 100}%`
				percentWidth = `${((Math.abs(diffX * scale) / (width * scale))) * 100}%`
				percentHeight = `${((Math.abs(diffY * scale) / (height * scale))) * 100}%`
			}
			if (diffX > 0 && diffY <= 0) {
				percentX = `${(((startX * scale) / (width * scale))) * 100}%`
				percentY = `${((((startY * scale) - Math.abs(diffY * scale)) / (height * scale))) * 100}%`
				percentWidth = `${((Math.abs(diffX * scale) / (width * scale))) * 100}%`
				percentHeight = `${((Math.abs(diffY * scale) / (height * scale))) * 100}%`
			}
			if (diffX > 0 && diffY > 0) {
				percentX = `${(((startX * scale) / (width * scale))) * 100}%`
				percentY = `${(((startY * scale) / (height * scale))) * 100}%`
				percentWidth = `${((Math.abs(diffX * scale) / (width * scale))) * 100}%`
				percentHeight = `${((Math.abs(diffY * scale) / (height * scale))) * 100}%`
			}
		} else {
			// when index is false, we're currently drawing the bounding rect. Don't use scale on x/y, only to calculate width
			if (diffX <= 0 && diffY <= 0) {
				percentX = `${((startX / scale) - Math.abs(diffX / scale))}px`
				percentY = `${((((startY / scale) - Math.abs(diffY / scale))))}px`
				percentWidth = `${((Math.abs(diffX / scale)))}px`
				percentHeight = `${((Math.abs(diffY / scale)))}px`
			}
			if (diffX <= 0 && diffY > 0) {
				percentX = `${((((startX / scale) - Math.abs(diffX / scale))))}px`
				percentY = `${(((startY / scale)))}px`
				percentWidth = `${((Math.abs(diffX / scale)))}px`
				percentHeight = `${((Math.abs(diffY / scale)))}px`
			}
			if (diffX > 0 && diffY <= 0) {
				percentX = `${(((startX / scale)))}px`
				percentY = `${((((startY / scale) - Math.abs(diffY / scale))))}px`
				percentWidth = `${((Math.abs(diffX / scale)))}px`
				percentHeight = `${((Math.abs(diffY / scale)))}px`
			}
			if (diffX > 0 && diffY > 0) {
				percentX = `${(((startX / scale)))}px`
				percentY = `${(((startY / scale)))}px`
				percentWidth = `${((Math.abs(diffX / scale)))}px`
				percentHeight = `${((Math.abs(diffY / scale)))}px`
			}
		}

		if (state.data.isSelectingRule === index) {
			return null
		}

		const handleEnter = async () => {
			console.log('dispatch 22')
			await dispatch({
				...state, data: {
					...state.data,
					isHovered: index
				}
			})
		}

		const handleLeave = async () => {
			console.log('dispatch 23')
			await dispatch({
				...state, data: {
					...state.data,
					isHovered: false
				}
			})
		}

		return (
			<div
				onMouseEnter={handleEnter}
				onMouseLeave={handleLeave}
				key={`bounding_${index}`}
				style={{
					transition: isClicked ? '0' : 'all 0.1s, box-shadow 0.3s, opacity 0.3s',
					// pointerEvents: 'none',
					position: 'absolute',
					zIndex: 10,
					left: percentX,
					top: percentY,
					width: percentWidth,
					height: percentHeight,
					border: index !== false ? '1px #777 solid' : '2px #333 dashed',
					borderRadius: '2px',
					boxShadow: state.highlight_rule_boundary === index ? '0px 0px 0px 10000px rgba(0,0,0,0.4)' : '0px 0px 0px 10000px rgba(0,0,0,0.0)',
					opacity: state.highlight_rule_boundary !== index && state.highlight_rule_boundary !== false ? 0.1 : 1
				}}
			>
				{/* <div style={{ position: 'absolute', top: '-15px', display: 'flex', color: 'black', justifyContent: 'space-between' }}>
                    <p style={{ position: 'absolute', fontSize: '12px' }}>{rect_key}</p>
                    <p style={{ position: 'absolute', fontSize: '12px' }}>{rect_val}</p>
                </div> */}
				{index !== false && <button style={{ opacity: state.data.isHovered === index ? 1 : 0, pointerEvents: 'all', transition: '0.2s', transformOrigin: 'top right', position: 'absolute', zIndex: 100, top: '0px', right: '0px', transform: `scale(0.5)`, backgroundColor: 'rgba(255, 0, 0, 0.7)' }} type="button" className="btn-close" aria-label="Close" onClick={() => deleteBoundingRect(index)}></button>}
			</div>
		)
	}

	const deleteBoundingRect = async (index) => {
		const rules = [...state.data.rules]
		rules.splice(index, 1)

		const boundingRectList = [...state.document.boundingRectList]
		boundingRectList.splice(index, 1)

		console.log('dispatch 24')
		await dispatch({ ...state, document: { ...state.document, boundingRectList }, data: { ...state.data, rules } })
	}

	const addTextstToState = async (texts) => {
		console.log('texts')
		console.log(texts)

		console.log('dispatch 25')
		await dispatch({ ...state, texts })
	}

	// const handleSelectText = async () => {
	//     if(isSelectingText){
	//         const texts = selectedTexts
	//         setSelectedTexts()
	//     }
	// }

	const textRenderer = ({ str, itemIndex }) => {
		// need to add "selected", "start_text", "end_text" classes and check against defined rules

		// I need a texts array that I can splice and remove up to the start term, and after the end term


		// return (<div style={{ display: showText ? 'auto' : 'none' }}>{str}</div>)

		// need to explain this portion before getting to deep into it
		// I need a state variable to set, isSelectingText or something, 
		// When mouseDown happens, if isSelectingRule and isSelectingText == false, setIsSelectingText(true) and every mouseEnter read the isSelectingText value to add text to selected rule

		return (<div /*onMouseEnter={handleSelectText}*/ className={'text_layer_item'} style={{ display: 'none' }}>{str}</div>)
	}

	return (
		<>
			<div style={{ position: 'absolute', top: '60px', right: '150px', zIndex: 5 }}>
				{coords[0].toFixed(3)}, {coords[1].toFixed(3)}, {/*({renderer.width}, {renderer.height}, {renderer.scale})*/}
			</div>
			<div
				ref={bounding}
				style={{ display: 'flex', overflow: 'auto', height: '100%', boxSizing: 'border-box', background: 'silver', width: '100%', position: 'relative' }}
			// onMouseMove={(e) => handleMouseOver(e, setCoords, bounds, renderer)}
			>
				<PDFControls state={state} dispatch={dispatch} />
				<Document
					error={(e) => { console.log("regular error", e) }}
					style={{ transition: '0.1s', width: '100%' }}
					noData={() => <FileUploader />}
					file={url}
					onLoadSuccess={(e) => handlePDFLoad(e, setRender)}
					onMouseDown={mouseDown}
					onMouseUp={mouseUp}
					onLoadProgress={(d) => { console.log(`progress: ${JSON.stringify(d)}`) }}
					onSourceError={(e) => { console.log("Source error", e) }}
					onGetTextSuccess={addTextstToState}
					onGetTextError={(e) => { console.log("TEXT ERROR", e) }}
				>
					<Page
						pageNumber={1}
						onMouseMove={(e) => handleMouseOver(e, setCoords, bounds, renderer)}
						width={renderer ? renderer.width : 0}
						renderTextLayer={true}
						// renderTextLayer={state.data.isSelectingRule !== false && state.data.rules[state.data.isSelectingRule].type.indexOf('text') !== -1}
						// onLoadSuccess={(e) => handlePDFLoad(e, setRender)}
						onGetTextSuccess={addTextstToState}
						onGetTextError={(e) => { console.log("TEXT ERROR", e) }}
						renderAnnotationLayer={false}
						style={{ transition: '0.1s' }}
						customTextRenderer={textRenderer}
					>
						{state.document.boundingRectList.map((rect, index) => {
							return (DrawBoundingRect(rect, index))
						})}
						{/* {showBoundingRect && state.data.isSelectingRule !== false && DrawBoundingRect(boundingRect, state.data.isSelectingRule)} */}
						{showBoundingRect && state.data.isSelectingRule !== false && DrawBoundingRect(boundingRect, false)}
					</Page>
				</Document>
			</div>
		</>
	)
}

export default Preview