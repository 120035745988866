import React, { useEffect, useState } from 'react'

const CenteredLoadingSpinner = (props) => {
    const [show, setShow] = useState(false)
    const { scale = 1, color = '#777', value= '' } = props
    useEffect(() => {
        setShow(true)
    }, [])

	if(props.isProgressBar){
		return (
			<div style={{width: '100px', display: 'flex', height: '5px', background: 'white', overflow: 'hidden', position: 'fixed', bottom: '10px', right: '10px'}}>
				<div style={{width: `${props.progress}%`, minWidth: '5px', borderRadius: '5px', background: 'silver'}}></div>
			</div>
		)
	}

    return (
        <div style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%', overflow: 'hidden', transform: `scale(${scale})`, display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '0.2s', opacity: show ? 1 : 0, flexDirection: 'column' }}>
            <div className={'loading_spinner'} style={{ width: '55px', height: '55px', borderRadius: '50%', borderRight: `3px ${color} solid`, borderBottom: `3px ${color} solid`, borderTop: `3px ${color} solid`, borderLeft: `3px transparent solid`, boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.15), 0px 0px 13px 0px rgba(0,0,0,0.15) inset' }}></div>
			<div style={{fontSize: '10pt', paddingLeft: '6px', color, opacity: 0.6, fontFamily: 'Tahoma', display: 'flex', alignSelf: 'center'}}>{value}</div>
        </div>
    )
}

export default CenteredLoadingSpinner