const config = {
    // HOST_URL: process.env.HOST_URL || 'http://localhost:3000',
    // API_URL: process.env.API_URL || 'http://localhost:3030',
    HOST_URL: process.env.HOST_URL || 'https://pdf-parsley.com',
    API_URL: process.env.API_URL || 'https://pdf-parsley-api.herokuapp.com',
    FIREBASE_REFRESH_URL: 'https://securetoken.googleapis.com/v1/token',
    FIREBASE_API_KEY: 'AIzaSyCNDgMEfbxIbIEk7gOfxb1yy_c-xbOCuVo',
	SEQUENCE_ENDPOINT: 'https://pdf-parsley.s3.us-east-1.amazonaws.com/img/landing_sequence_60',
	IMAGE_DOMAIN: 'https://pdf-parsley.s3.us-east-1.amazonaws.com' // if using localhost, run a python server in the folder with images -> img -> landing_sequence_60 etc
}

export default config