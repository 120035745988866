import React, {useState} from 'react'
import { Button } from 'react-bootstrap'
import { useGlobalState } from './Context'
import { inspect } from 'util'
// for uploading pdf to cloud storage
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage"

const storage = getStorage()
// will likely need to store the pdf at some tenant-accessible area.
// maybe per user - uid would be easier to use at first



// Style the Button component
const FileUploader = (props) => {
    const [state, dispatch] = useGlobalState()
    const [progress, setProgress] = useState(0)

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null)

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (e) => {
        hiddenFileInput.current.click()
    }
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = async (e) => {
        const upload = e.target.files[0]
        console.log("file upload")
        console.log(upload)
        /*
        const formData = new FormData()
        formData.append('pdf_upload', upload)

        // upload file via firebase to cloud storage, pass the reference to server so it can download and manipulate the file
        const storageRef = ref(storage, `${state.user.uid}/${upload.name}`)
        const uploadTask = uploadBytesResumable(storageRef, upload)
        uploadTask.on('state_changed',
            // success
            (snapshot) => {
                console.log("progress", progress)
                setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            },
            // error
            (snapshot) => {
                console.log("error")
            },
            async () => {
                console.log('finally')
                const url = getDownloadURL(uploadTask.snapshot.ref).then(URL => {
                    return URL
                })
                */
               
                const newState = {
                    ...state,
                    data: {
                        ...state.data,
                        // download_url: url,
                        file_name: upload.name,
                        file: upload
                    }
                }
                console.log('dispatch 8')
                await dispatch({...newState})
                /*
            }
        )

        */

        // await fetch('http://localhost:3030/pdf/upload', {
        //     method: 'POST',
        //     body: formData
        // }).then(async (d) => {
        //     console.log("d")
        //     console.log(d)
        //     const data = await d.json()
        //     console.log(data)

        //     const url = data.url
        //     const newState = {
        //         ...state,
        //         data: {
        //             ...state.data,
        //             file: url,
        //             file_name: data.file
        //         }
        //     }
        //     console.log('newState', newState)

        //     await dispatch(newState)
        // })
        // props.handleFile(upload)
    }

    return (
        <>
            <Button className={"clean_button clean_primary"} onClick={handleClick} style={{ fontWeight: 300 }}>
                <div>Upload a pdf to get started</div>
            </Button>
            {progress && progress !== 100 ? (
                <div>
                    <div style={{width: '200px'}}>
                        <div style={{width: `${progress}%`}}></div>
                    </div>
                    <div>{Math.round(progress)}%</div>
                </div>
            ) : (
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    accept={'.pdf'}
                    name="pdf_upload"
                    style={{ display: 'none' }}
                />
            )}
        </>
    )
}
export default FileUploader