import React from 'react'
// import './Documentation.css'

const Documentation = () => {
	return (
		<div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
			<div className="flex flex-grow flex-col gap-3">
				<div className="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap">
					<div className="markdown prose w-full break-words dark:prose-invert light">
						<h2>API Documentation for Parsley</h2>
						<h3>Introduction</h3>
						<p>This API provides a convenient and secure way to access Parsley data and functionality. All API
							requests and
							responses use JSON as the default data format.</p>
						<h3>Endpoints</h3>
						<p>All endpoints are relative to the base URL: <code>https://api.parsley.com/v1</code>
						</p>
						<h4>User Management</h4>
						<ul>
							<li>
								<code>GET /users</code> - Retrieve a list of all users.
							</li>
							<li>
								<code>GET /users/{"{"}userId{"}"}</code> - Retrieve the details of a specific user.
							</li>
							<li>
								<code>POST /users</code> - Create a new user.
							</li>
							<li>
								<code>PUT /users/{"{"}userId{"}"}</code> - Update the details of a specific user.
							</li>
							<li>
								<code>DELETE /users/{"{"}userId{"}"}</code> - Delete a specific user.
							</li>
						</ul>
						<h4>Product Management</h4>
						<ul>
							<li>
								<code>GET /products</code> - Retrieve a list of all products.
							</li>
							<li>
								<code>GET /products/{"{"}productId{"}"}</code> - Retrieve the details of a specific product.
							</li>
							<li>
								<code>POST /products</code> - Create a new product.
							</li>
							<li>
								<code>PUT /products/{"{"}productId{"}"}</code> - Update the details of a specific product.
							</li>
							<li>
								<code>DELETE /products/{"{"}productId{"}"}</code> - Delete a specific product.
							</li>
						</ul>
						<h3>Request and Response Format</h3>
						<p>All requests must include a <code>Content-Type</code> header with the value of
							<code>application/json</code>.
						</p>
						<p>All responses will include a <code>Content-Type</code> header with the value of
							<code>application/json</code>.
						</p>
						<p>Successful responses will have a status code of <code>200</code> and contain a JSON response body
							with the
							requested data.</p>
						<p>Error responses will have a status code of <code>4xx</code> or <code>5xx</code> and contain a JSON
							response
							body with the error message.</p>
						<h3>Authentication</h3>
						<p>API requests must include a <code>Authorization</code> header with a valid API key.</p>
						<p>To obtain an API key, please contact the Parsley team.</p>
						<h3>Error Handling</h3>
						<p>If an error occurs while processing a request, the API will return a JSON response with a relevant
							error
							message and a <code>4xx</code> or <code>5xx</code> status code.</p>
						<p>Error responses will have the following structure:</p>
						<pre>
							<div className="bg-black mb-4 rounded-md">
								<div className="flex items-center relative text-gray-200 bg-gray-800 px-4 py-2 text-xs font-sans">
									<span className="">json</span>
									<button className="flex ml-auto gap-2">
										<svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
											<rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
										</svg>
										Copy code
									</button>
								</div>
								<div className="p-4 overflow-y-auto">
									<code className="!whitespace-pre hljs language-json">
										<span className="hljs-punctuation">{"{"}</span>
										<span className="hljs-attr">"error"</span>
										<span className="hljs-punctuation">:</span> <span className="hljs-string">"Error message"</span>
										<span className="hljs-punctuation">{"}"}</span>
									</code>
								</div>
							</div>
						</pre>

						<h3>Examples</h3>
						<h4>Get a list of all users</h4>
						<h5>Request</h5>
						<pre>
							<div className="bg-black mb-4 rounded-md">
								<div className="flex items-center relative text-gray-200 bg-gray-800 px-4 py-2 text-xs font-sans">
									<span className="">bash</span>
									<button className="flex ml-auto gap-2">
										<svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2">

											</path>
											<rect x="8" y="2" width="8" height="4" rx="1" ry="1">

											</rect>
										</svg>Copy code</button>
								</div>
								<div className="p-4 overflow-y-auto">
									<code className="!whitespace-pre hljs language-bash">GET /users
										Authorization: 1234567890abcdef
										Content-Type: application/json
									</code>
								</div>
							</div>
						</pre>
						<h5>Response</h5>
						<pre>
							<div className="bg-black mb-4 rounded-md">
								<div className="flex items-center relative text-gray-200 bg-gray-800 px-4 py-2 text-xs font-sans">
									<span className="">json</span>
									<button className="flex ml-auto gap-2">
										<svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
											<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2">

											</path>
											<rect x="8" y="2" width="8" height="4" rx="1" ry="1">

											</rect>
										</svg>Copy code</button>
								</div>
								<div className="p-4 overflow-y-auto">
									<code className="!whitespace-pre hljs language-json">
										<div>HTTP/<span className="hljs-number">1.1</span> <span className="hljs-number">200</span> OK</div>
										Content-Type<span className="hljs-punctuation">:</span> application/json
										<div className="hljs-punctuation">{"["}</div>
										<div className="hljs-punctuation">{"{"}</div>
										<span className="hljs-attr">"id"</span>
										<span className="hljs-punctuation">:</span> <span className="hljs-number">1</span>
										<span className="hljs-punctuation">,</span>
										<div className="hljs-attr">"name"</div>
										<span className="hljs-punctuation">:</span> <span className="hljs-string">"John Doe"</span>
										<span className="hljs-punctuation">,</span>
										<span className="hljs-attr">"email"</span>
										<span className="hljs-punctuation">:</span> <span className="hljs-string">"johndoe@example.com"</span>
										<span className="hljs-punctuation">{"}"}</span>
										<span className="hljs-punctuation">,</span>
										<span className="hljs-punctuation">{"{"}</span>
										<span className="hljs-attr">"id"</span>
										<span className="hljs-punctuation">:</span> <span className="hljs-number">2</span>
										<span className="hljs-punctuation">,</span>
										<span className="hljs-attr">"name"</span>
										<span className="hljs-punctuation">:</span> <span className="hljs-string">"Jane Doe"</span>
										<span className="hljs-punctuation">,</span>
										<span className="hljs-attr">"email"</span>
										<span className="hljs-punctuation">:</span> <span className="hljs-string">"janedoe@example.com"</span>
										<span className="hljs-punctuation">{"}"}</span>
										<span className="hljs-punctuation">{"]"}</span>
									</code>
								</div>
							</div>
						</pre>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Documentation