import React from 'react'
import parsley from './img/parsley_1.png'

import { FormControl, Button } from 'react-bootstrap'

import CenteredLoadingSpinner from './Loader'

function Terms() {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
			<div style={{ fontSize: '18px', fontWeight: 100, background: 'white', width: '8.5in', borderRadius: '4px', boxShadow: '0px 1px 5px 1px rgba(0,0,0,0.15', padding: '0.5in', paddingTop: '0.75in', display: 'flex', flexDirection: 'column' }}>
				<ol>
					<li className="h3">
						Introduction
						<p style={{ fontSize: '18px', fontWeight: 100 }}>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, PDF Parsley accessible at pdf-parsley.com.</p>
					</li>
					<li className="h3">
						General Use
						<p style={{ fontSize: '18px', fontWeight: 100 }}>By using our Website, you accepted these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our Website.</p>
					</li>
					<li className="h3">
						Intellectual Property Rights
						<p style={{ fontSize: '18px', fontWeight: 100 }}>Unless otherwise stated, we or our licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</p>
					</li>
					<li className="h3">
						License to use website
						<p style={{ fontSize: '18px', fontWeight: 100 }}>You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.</p>
					</li>
				</ol>
				{/* <hr /> */}
				<p style={{ paddingLeft: '60px' }}>
					<h5>You must not:</h5>
					<ul style={{ margin: '20px' }}>
						<li>republish material from this website (including republication on another website)</li>
						<li>sell, rent or sub-license material from the website</li>
						<li>show any material from the website in public</li>
						<li>reproduce, duplicate, copy or otherwise exploit material on our website for a commercial purpose</li>
						<li>edit or otherwise modify any material on the website</li>
						<li>redistribute material from this website except for content specifically and expressly made available for redistribution.</li>
					</ul>
					Where content is specifically made available for redistribution, it may only be redistributed within your organisation.
				</p>
				<ol start="5">
					<li className="h3">Acceptable use</li>
				</ol>
				<div style={{ paddingLeft: '60px' }}>
					<ul style={{ margin: '20px' }}>
						<li>You must not use our website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</li>
						<li>You must not use our website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</li>
						<li>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to our website without our express written consent.</li>
						<li>You must not use our website to transmit or send unsolicited commercial communications.</li>
						<li>You must not use our website for any purposes related to marketing without our express written consent.</li>
					</ul>
				</div>
				<ol start="6">
					<li className="h3">Restricted access</li>
				</ol>
				<div style={{ paddingLeft: '60px' }}>
					<p>We reserve the right to restrict access to areas of our website, or indeed our whole website, at our discretion and without notice.</p>
					<p>If we provide you with a user ID and password to enable you to access restricted areas of our website or other content or services, you must ensure that the user ID and password are kept confidential at all times.</p>
					<p>We reserve the right to disable your user ID and password at any time in our sole discretion without notice or explanation.</p>
				</div>
				<ol start="7">
					<li className="h3">Content</li>
				</ol>
				<div style={{ paddingLeft: '60px' }}>
					<p>Your content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of amounting to a criminal offence or give rise to a civil liability, or otherwise be contrary to the law of any country or territory where it is or may be published or received.</p>
					<p>Your content, and the use of your content by us in accordance with these terms and conditions, must not: infringe any copyright, moral right, database right, trade mark right</p>
				</div>
			</div>
		</div>
	)
}

export default Terms