import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import ModelCreator from './ModelCreator'

import parsley_5 from './img/parsley_5.png'
import parsley_grid from './img/parsley_grid.png'

import {
	BrowserRouter,
	Routes,
	Route,
	useNavigate,
	Link,
} from 'react-router-dom'

import './Landing.css'
import './Animations.css'
import './FormElements.css'

import { GlobalStateProvider, useGlobalState } from './Context'

import { Button, Modal, Navbar, Nav, NavDropdown, InputGroup, FormControl } from 'react-bootstrap'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import SignUp from './SignUp'
import SignIn from './SignIn'
import Dashboard from './Dashboard'

import { Helmet } from 'react-helmet'
import config from './config'
import CenteredLoadingSpinner from './Loader'
import Terms from './Terms'
import PrivacyPolicy from './PrivacyPolicy'
import Documentation from './Documentation'
import TopNav from './TopNav'
import Demo from './Demo'
import ScrollBackground from './ScrollBackground'

const firebase_config = {
	apiKey: "AIzaSyBGOiN1iNbI0fpmST8CWFn5RhktJ-iRvjU",
	authDomain: "pdf-parsley.firebaseapp.com",
	projectId: "pdf-parsley",
	storageBucket: "pdf-parsley.appspot.com",
	messagingSenderId: "100894818900",
	appId: "1:100894818900:web:901ff7383995cf19b0a3c1"
}
firebase.initializeApp(firebase_config)

const root = ReactDOM.createRoot(document.getElementById('root'))

const BetaPopup = ({ modalClassName, handleClose }) => {
	return (
		<div
			className={`beta_modal`}
			style={{ transition: 'bottom 0.3s, opacity 0.4s', background: 'white', zIndex: 15, padding: '15px', width: '300px', maxWidth: '100%', opacity: modalClassName == 'show_modal' ? 1 : 0, bottom: modalClassName == 'show_modal' ? '10px' : '-300px' }}
		>
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>Welcome!</h3>
					{/* <Button className={'btn-close'} onClick={() => handleClose(true)}></Button> */}
				</div>
				<p>We're in beta!</p>
				<p>Sign up to apply for our closed beta and secure <span style={{ fontWeight: 'bold' }}>free</span> service <span style={{ fontWeight: 'bold' }}>FOREVER</span>.</p>
				<br />
				<p>Terms and conditions apply.</p>
				<Button key={'close_modal_cancel'} variant="outline-secondary" style={{ fontWeight: 300 }} onClick={() => { return handleClose(false) }}>Dismiss</Button>
			</div>
		</div>
	)
}

const Landing = () => {
	const [email, setEmail] = useState('')
	const [showPopup, setShowPopup] = useState(String(localStorage.getItem('show_beta_popup')) === 'false' ? false : true)
	const colors = ['#888', '#999', '#777']
	const [state, dispatch] = useGlobalState()
	const [modalClassName, setModalClassName] = useState('')

	const navigate = useNavigate()


	const [pricing, setPricing] = useState(
		{ // eventually pull this from the backend, in case pricing changes - I can maintain a historical price chart for grandfathered in clients
			parse: [
				{ start: 0, end: 30, price: 0 },
				{ start: 31, end: 300, price: 0.185 },
				{ start: 301, end: 1000, price: 0.165 },
				{ start: 1001, end: 5000, price: 0.135 },
				{ start: 5001, end: Infinity, price: 0.095 },
			],
			send: [
				{ start: 0, end: 30, price: 0 },
				{ start: 31, end: 300, price: 0.085 },
				{ start: 301, end: 1000, price: 0.065 },
				{ start: 1001, end: 5000, price: 0.045 },
				{ start: 5001, end: Infinity, price: 0.035 },
			],
			store: [
				// might have to convert this to bytes so its less dangerous to do math on
				{ start: 0, end: 0.5, price: 0 },
				{ start: 0.5001, end: 10, price: 0.57 },
				{ start: 10.0001, end: 150, price: 0.49 },
				{ start: 150.0001, end: 1000, price: 0.40 },
				{ start: 1000.0001, end: Infinity, price: 0.31 },
			],
		}
	)

	useEffect(() => {
		const setPricing = async () => {
			const newState = { ...state, tiers: pricing }
			await dispatch({ ...newState })
		}

		if (!state.tiers.parse) {
			setPricing()
		}
	}, [pricing])

	useEffect(() => {
		const getCurrentPricing = async () => {
			await fetch(`${config.API_URL}/get-pricing`).then(async d => {
				const res = await d.json()
				console.log(res)
				if (res.status == 'success') {
					const pricing = res.pricing
					setTimeout(async () => {
						await setPricing({ ...pricing })
					}, 1500)
				} else {
					throw new Error(res.message)
				}
			}).catch((e) => {
				console.log(e)
			})
		}
		if (!pricing.parse) {
			getCurrentPricing()
		}
	}, [])

	useEffect(() => {
		if (showPopup) {
			console.log('showing popup inside component')
			setShowPopup(true)
			setTimeout(() => {
				setModalClassName('show_modal')
			}, 1500)
		}
	}, [])


	const handleChange = async (e) => {
		await setEmail(e.target.value)
		console.log('dispatch 9')
		await dispatch({ ...state, sign_up_email: e.target.value })
	}

	const handleClose = (hide = true) => {
		localStorage.setItem('show_beta_popup', hide)
		setShowPopup(false)
		setModalClassName('')
	}

	const Footer = () => {
		return (
			<>
				<footer style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '60px', background: '#efffef', zIndex: 3 }}>
					<div style={{ display: 'flex', width: '60%', maxWidth: '800px', alignSelf: 'center', justifyContent: 'space-between' }}>
						{/* <div style={{ display: 'flex', flexDirection: 'column' }} className="footer-links"> */}
						{/* <Nav.Link style={{ color: '#57afdf', padding: 0, margin: 5 }} onClick={() => navigate("/")}>Home</Nav.Link> */}
						{/* <Nav.Link style={{ color: '#57afdf', padding: 0, margin: 5 }} onClick={() => navigate("/about")}>About</Nav.Link> */}
						{/* <Nav.Link style={{ color: '#57afdf', padding: 0, margin: 5 }} onClick={() => navigate("/docs")}>API Documentation</Nav.Link> */}
						{/* </div> */}
						<div style={{ display: 'flex', flexDirection: 'column' }} className="footer-links">
							<Nav.Link style={{ color: '#57afdf', padding: 0, margin: 5 }} onClick={() => navigate("/privacy-policy")}>Privacy Policy</Nav.Link>
							<Nav.Link style={{ color: '#57afdf', padding: 0, margin: 5 }} onClick={() => navigate("/terms-and-conditions")}>Terms and Conditions</Nav.Link>
							<Nav.Link style={{ color: '#57afdf', padding: 0, margin: 5 }} href="mailto:support@pdf-parsley.com">Contact</Nav.Link>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column' }} className="footer-links">
							<div style={{ color: '#555', padding: 0, margin: 5 }}>PDF Parsley</div>
							<div style={{ color: '#555', padding: 0, margin: 5 }}>P.O. Box 87713</div>
							<div style={{ color: '#555', padding: 0, margin: 5 }}>Vancouver, WA 98687</div>
						</div>
					</div>
					<div className="footer-copyright" style={{ textAlign: 'center', fontWeight: 400, marginTop: '50px', marginBottom: '5px', fontWeight: 100, fontSize: '14px' }}>Copyright &copy; {new Date().getFullYear()} PDF Parsley</div>
				</footer>
			</>
		)
	}

	return (
		<>
			<div className="landing-banner" id="its_easy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#e1ffd7', background: 'transparent', height: '200%', minWidth: '100vw', padding: '0px', zIndex: 5 }}>
				<ScrollBackground />
				<BetaPopup modalClassName={modalClassName} handleClose={handleClose} />

				<div className="sticky-block-container-1" style={{ display: 'flex', flexDirection: 'column', background: 'transparent' }}>
					<div className={'spinner_container'} >
						<img alt="" src={parsley_5} style={{ position: 'absolute', zIndex: 1, userSelect: 'none', WebkitUserSelect: 'none' }} className="spinner" />
					</div>
					<div className={'super_heading'}>
						<h1 style={{ fontSize: '32pt' }}>Supercharge your data entry with Parsley</h1>
						<p style={{ fontSize: '22pt', fontWeight: 200, marginTop: '50px' }}>Reduce data entry costs, increase data entry speed, and improve data accuracy</p>
					</div>
				</div>

				<div className={'sticky-container-wrapper'}>
					<div id="extract" style={{ position: 'absolute', top: '1000px' }}></div>
					<div className={'sticky-block-container-2'}>
						<div className={'sticky-block-1'}>
							<h1>Analyze & Extract</h1>
							<p style={{ marginTop: '40px', fontSize: '24px', fontWeight: 200 }}>
								Send Parsley documents through API requests or as email attachments. <br /><br />
								Parsley will extract data following a template you specify.
							</p>
						</div>
					</div>
					<div id="transform"></div>
					<div className={'sticky-block-container-3'}>
						<div className={'sticky-block-2'}>
							<h1>Transform & Send</h1>
							<p style={{ marginTop: '40px', fontSize: '24px', fontWeight: 200 }}>
								Shape data to be recieved by your existing systems. <br /><br />
								Parsley will send your transformed data via webhook or securely surface it for your API to request.
							</p>
						</div>
					</div>
				</div>
				<div id="pricing">
					<div className={'parsley_grid_image'} style={{ backgroundImage: `url(${parsley_grid})` }} />
					<div style={{ display: 'flex', flexDirection: 'column', maxWidth: '900px', justifyContent: 'center', paddingTop: '0px', zIndex: 2 }}>
						<div style={{ textAlign: 'center' }}>
							<h1>Parsley scales with your growth</h1>
							<p style={{ fontSize: '24px', fontWeight: 200 }}>
								Whether you're an industry leader or just starting out, we've got a plan tailored just for you.
							</p>
						</div>

						{!pricing.parse && <CenteredLoadingSpinner />}

						{pricing.parse && (<div className={'pricing_container'}>
							<div className={'pricing_card'}>
								<h3>Parse</h3>
								<div style={{ background: '#fafaff', boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.35)', fontSize: '13pt', fontWeight: 200, padding: '10px', borderRadius: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
										<div style={{ fontSize: '15pt', fontWeight: 400 }}>Requests</div>
										<div>{`${pricing.parse[0].start} - ${pricing.parse[0].end}`}</div>
										<div>{`${pricing.parse[1].start} - ${pricing.parse[1].end}`}</div>
										<div>{`${pricing.parse[2].start} - ${pricing.parse[2].end}`}</div>
										<div>{`${pricing.parse[3].start} - ${pricing.parse[3].end}`}</div>
										<div>More than {pricing.parse[4].start}</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
										<div style={{ fontSize: '15pt', fontWeight: 400 }}>Price</div>
										<div>Free</div>
										<div>${pricing.parse[1].price.toFixed(3)}</div>
										<div>${pricing.parse[2].price.toFixed(3)}</div>
										<div>${pricing.parse[3].price.toFixed(3)}</div>
										<div>${pricing.parse[4].price.toFixed(3)}</div>
									</div>
								</div>
							</div>
							<div className={'pricing_card'}>
								<h3>Send</h3>
								<div style={{ background: '#fafaff', boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.35)', fontSize: '13pt', fontWeight: 200, padding: '10px', borderRadius: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
										<div style={{ fontSize: '15pt', fontWeight: 400 }}>Calls</div>
										<div>{`${pricing.send[0].start} - ${pricing.send[0].end}`}</div>
										<div>{`${pricing.send[1].start} - ${pricing.send[1].end}`}</div>
										<div>{`${pricing.send[2].start} - ${pricing.send[2].end}`}</div>
										<div>{`${pricing.send[3].start} - ${pricing.send[3].end}`}</div>
										<div>More than {pricing.send[4].start}</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
										<div style={{ fontSize: '15pt', fontWeight: 400 }}>Price</div>
										<div>Free</div>
										<div>${pricing.send[1].price.toFixed(3)}</div>
										<div>${pricing.send[2].price.toFixed(3)}</div>
										<div>${pricing.send[3].price.toFixed(3)}</div>
										<div>${pricing.send[4].price.toFixed(3)}</div>
									</div>
								</div>
							</div>
							<div className={'pricing_card'}>
								<h3>Store</h3>
								<div style={{ background: '#fafaff', boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.35)', fontSize: '13pt', fontWeight: 200, padding: '10px', borderRadius: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
										<div style={{ fontSize: '15pt', fontWeight: 400 }}>Size (GB)</div>
										<div>{`${pricing.store[0].start} - ${pricing.store[0].end}`}</div>
										<div>{`${pricing.store[1].start} - ${pricing.store[1].end}`}</div>
										<div>{`${pricing.store[2].start} - ${pricing.store[2].end}`}</div>
										<div>{`${pricing.store[3].start} - ${pricing.store[3].end}`}</div>
										<div>More than {pricing.store[4].start.toFixed(0)}</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
										<div style={{ fontSize: '15pt', fontWeight: 400 }}>Price</div>
										<div>Free</div>
										<div>${pricing.store[1].price.toFixed(3)}</div>
										<div>${pricing.store[2].price.toFixed(3)}</div>
										<div>${pricing.store[3].price.toFixed(3)}</div>
										<div>${pricing.store[4].price.toFixed(3)}</div>
									</div>
								</div>
							</div>
						</div>)}
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0px', marginTop: '100px' }}>
							<h3>Join our beta!</h3>
							<div style={{ minWidth: '300px', maxWidth: '350px', maxHeight: '38px', display: 'flex' }}>
								<FormControl aria-label="sign_up" placeholder={'john@planet10.com'} value={email} onChange={handleChange} aria-describedby="inputGroup-sizing-sm" />
								<Nav.Link style={{ padding: 0, margin: 0 }} onClick={() => navigate("/sign-up")}>
									<Button style={{ marginLeft: '5px', minWidth: '100px' }} variant="outline-secondary" id="sign_up_1">
										Join Beta
									</Button>
								</Nav.Link>
							</div>
						</div>
					</div>
				</div>




				<Footer />
			</div>
			{/* </div> */}
		</>
	)
}


const About = () => {
	return (<p>About us page</p>)
}

root.render(
	<>
		<GlobalStateProvider>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', overscrollBehavior: 'contain' }}>
				<div style={{ minHeight: '4000px', marginTop: '50px' }}>
					<BrowserRouter>
						<TopNav />
						<Routes>
							<Route path="/" element={
								<>
									<Helmet>
										<title>PDF Parsley</title>
										<meta name="description" content="Welcome - PDF Parsley helps automate PDF intake and processing of Invoices, Sales Orders, Statements, and so much more." />
										<link rel="canonical" href="://pdf-parsley.com" />
									</Helmet>
									<Landing />
								</>
							} />
							<Route path="/sign-up" element={
								<>
									<Helmet>
										<title>PDF Parsley - Sign Up</title>
										<meta name="description" content="Get Started - Simplify your document processing with our generous free tier." />
										<link rel="canonical" href="://pdf-parsley.com/sign-up" />
									</Helmet>
									<SignUp />
								</>
							} />
							<Route path="/sign-in" element={
								<>
									<Helmet>
										<title>PDF Parsley - Sign In</title>
										<meta name="description" content="Get Building - Create models to parse your documents, for free." />
										<link rel="canonical" href="://pdf-parsley.com/sign-up" />
									</Helmet>
									<SignIn />
								</>
							} />

							<Route path="/about" element={
								<>
									<Helmet>
										<title>PDF Parsley - About</title>
										<meta name="description" content="Get Acquainted - Who we are, and why we're doing the things we're doing." />
										<link rel="canonical" href="://pdf-parsley.com/about" />
									</Helmet>
									<About />
								</>
							} />
							<Route path="/docs" element={
								<>
									<Helmet>
										<title>PDF Parsley - API Documentation</title>
										<meta name="description" content="Parsley API documentation provides detailed information on all available endpoints, request and response formats, authentication, and error handling. Learn how to access and interact with the Parsley data and functionality." />
										<link rel="canonical" href="://pdf-parsley.com/docs" />
									</Helmet>
									<Documentation />
								</>
							} />
							<Route path="/terms-and-conditions" element={
								<>
									<Helmet>
										<title>PDF Parsley - Terms and Conditions</title>
										<link rel="canonical" href="://pdf-parsley.com/terms-and-conditions" />
									</Helmet>
									<Terms />
								</>
							} />
							<Route path="/privacy-policy" element={
								<>
									<Helmet>
										<title>PDF Parsley - Terms and Conditions</title>
										<link rel="canonical" href="://pdf-parsley.com/privacy-policy" />
									</Helmet>
									<PrivacyPolicy />
								</>
							} />
							{/* <Route path="/create-model" element={
								<>
									<Helmet>
										<title>PDF Parsley - New Model</title>
										<link rel="canonical" href="://pdf-parsley.com/create-model" />
									</Helmet>
									<ModelCreator />
								</>
							} /> */}
							<Route path="/dashboard" element={
								<>
									<Helmet>
										<title>PDF Parsley - Home</title>
										<meta name="description" content="Dashboard - View incoming and outgoing requests, current usage statistics, and account / billing information." />
										<link rel="canonical" href="://pdf-parsley.com/dashboard" />
									</Helmet>
									<Dashboard />
								</>
							} />
							<Route path="/demo" element={
								<>
									<Helmet>
										<title>PDF Parsley - Demo</title>
										<meta name="description" content="Demo - Watch a quick overview of PDF Parsley in action." />
										<link rel="canonical" href="://pdf-parsley.com/demo" />
									</Helmet>
									<Demo />
								</>
							} />
							{/* <Route path="/account" element={() => null} /> */}
							{/* <Route path="/create-account" element={<CreateAccount />} /> */}
							{/* <Route index element={<LeagueStandings />} /> */}
						</Routes>
					</BrowserRouter>
				</div>
			</div>
		</GlobalStateProvider>
	</>
)