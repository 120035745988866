import React, { useState, useEffect, useContext, createContext, useReducer } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { initializeAuth, browserLocalPersistence, browserPopupRedirectResolver } from "firebase/auth"
import { MODULEDECLARATION_TYPES } from '@babel/types'
// import * as test from './test.pdf'


const config = {
	apiKey: "AIzaSyBGOiN1iNbI0fpmST8CWFn5RhktJ-iRvjU",
	authDomain: "pdf-parsley.firebaseapp.com",
	projectId: "pdf-parsley",
	storageBucket: "pdf-parsley.appspot.com",
	messagingSenderId: "100894818900",
	appId: "1:100894818900:web:901ff7383995cf19b0a3c1"
}
const app = firebase.initializeApp(config)
const auth = initializeAuth(app, {
	persistence: browserLocalPersistence,
	popupRedirectResolver: browserPopupRedirectResolver
})

console.log(auth.currentUser)
// maybe try to sign in and fill in user by default


// possibly read from local storage to pre-populate this, otherwise just leave it empty for new sessions
const webhooks = Math.floor(Math.random() * 50000)
const webhook_success = Math.round(Math.random() * webhooks)
const webhook_fail = webhooks - webhook_success
const globalState = {
	tiers: { // eventually pull this from the backend, in case pricing changes - we can maintain a historical price chart for grandfathered in clients
		// parse: [
		// 	{ start: 0, end: 30, price: 0 },
		// 	{ start: 31, end: 300, price: 0.185 },
		// 	{ start: 301, end: 1000, price: 0.125 },
		// 	{ start: 1001, end: 5000, price: 0.075 },
		// 	{ start: 5001, end: Infinity, price: 0.055 },
		// ],
		// send: [
		// 	{ start: 0, end: 30, price: 0 },
		// 	{ start: 31, end: 300, price: 0.125 },
		// 	{ start: 301, end: 1000, price: 0.085 },
		// 	{ start: 1001, end: 5000, price: 0.045 },
		// 	{ start: 5001, end: Infinity, price: 0.035 },
		// ],
		// store: [
		// 	// might have to convert this to bytes so its less dangerous to do math on
		// 	{ start: 0, end: 0.5, price: 0 },
		// 	{ start: 0.5001, end: 10, price: 0.57 },
		// 	{ start: 10.0001, end: 150, price: 0.49 },
		// 	{ start: 150.0001, end: 1000, price: 0.40 },
		// 	{ start: 1000.0001, end: Infinity, price: 0.31 },
		// ],
	},
	screen: {
		path: 'overview',
		title: 'Dashboard'
	},
	data: {
		rules: [],
		file: '',
		// file: './test.pdf', // test document for rule building
		isSelectingRule: false,
		file_name: '',
		download_url: '',
		webhooks,
		models: Math.floor(Math.random() * 500),
		// vendors: Math.floor(Math.random() * 100),
		webhook_success,
		webhook_fail,
		details: {
			email: {
				webhook_url: '',
				store: false,
				vendor_id: '',
				vendor_name: '',
				subject_filter: ''
			},
			api: {
				webhook_url: '',
				store: false,
				vendor_id: '',
				vendor_name: '',
				host_domain: '',
			},
			manual: {
				webhook_url: '',
				store: false,
				user_email: ''
			}
		}
	},
	sign_up_email: '',
	user: 'isLoading',
	app,
	auth,
	document: {
		scale: 1.0,
		width: 0,
		height: 0,
		boundingRectList: []
	},
	ui_data: {},
	token_refresh: false,
}

const GlobalStateContext = createContext(globalState)
const DispatchStateContext = createContext(undefined)

const useGlobalState = () => [
	useContext(GlobalStateContext),
	useContext(DispatchStateContext)
]

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, newValue) => ({ ...state, ...newValue }), globalState)

	useEffect(() => {
		const auth = async () => {
			return await state.app.auth().onAuthStateChanged(async (user) => {
				let userResponse = user
				if(user === null){
					userResponse = 'isLoading'
				}
				console.log('dispatch 1')
				await dispatch({ ...state, user: userResponse })
			})
		}
		auth()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		console.log("updated state: ", state)
	}, [state])

	return (
		<GlobalStateContext.Provider value={state}>
			<DispatchStateContext.Provider value={dispatch}>
				{children}
			</DispatchStateContext.Provider>
		</GlobalStateContext.Provider>
	)
}

export {
	GlobalStateProvider,
	useGlobalState,
}