import React, { useRef, useEffect, useState, useContext } from 'react'

import { inspect } from 'util'
import { Navigate, Link, useNavigate } from 'react-router-dom'
import { GlobalStateProvider, useGlobalState } from './Context'
import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import CenteredLoadingSpinner from './Loader'
import StripeComponent from './StripeComponent'

import { signInHelper } from './helpers'
import SetupForm from './SetupForm'

import { DateTime } from 'luxon'

import config from './config'

import Overview from './Overview'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import dashboardIcon from './img/icons/dashboard.png'
import modelIcon from './img/icons/model.png'
import addIcon from './img/icons/add.png'
import chartIcon from './img/icons/chart.png'
import billingIcon from './img/icons/credit_card.png'
import dataIcon from './img/icons/data.png'
import errorIcon from './img/icons/error.png'
import emailIcon from './img/icons/mail.png'
import deleteIcon from './img/icons/remove.png'
import supportIcon from './img/icons/support.png'
import userIcon from './img/icons/user.png'
import logoutIcon from './img/icons/logout.png'

import Documents from './Documents'
import Billing from './Billing'
import Settings from './Settings'

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

const Support = () => {
	// contact us form (just do subject and message, since user is signed in and we'll know who they are from their account)
	return 'Support'
}

const Dashboard = (rule, deleteKey, isEditing, setIsEditing) => {
	const [state, dispatch] = useGlobalState()
	const [key, setKey] = useState(rule.id)
	const k = key

	const [screen, setScreen] = useState('overview')//state.screen.path)

	const screens = {
		overview: Overview,
		settings: Settings,
		billing: Billing,
		documents: Documents,
		support: Support
	}

	const screenTitles = {
		overview: 'Dashboard',
		settings: 'Account',
		documents: 'Models',
		support: 'Support',
		billing: 'Billing'
	}

	const handleScreenChange = async (path) => {
		console.log('screen change ' + path)
		console.log('screen change state: ', state)
		await setScreen(path)
	}

	useEffect(() => {
		const navigateState = async () => {
			const screenTitle = screenTitles[screen]
			await dispatch({...state, screen: {
				path: screen,
				title: screenTitle
			}})
		}
		navigateState()
	}, [screen])

	const handleKeyChange = (e) => {
		setKey(e.target.value)
	}

	const submitUpdate = () => {
		// update the item in the array with the replacement object getting built here?
	}

	const unsetEditting = () => {
		setIsEditing(false)
	}

	const setEditting = () => {
		setIsEditing(true)
	}

	const signOut = () => {
		firebase.auth().signOut().then(() => {
			localStorage.removeItem('refresh_token')
			console.log('dispatch 11')
			dispatch({ ...state, user: false })
		})
	}

	const SideBarRow = ({ screenName, text }) => {
		const icons = {
			overview: dashboardIcon,
			documents: modelIcon,
			billing: billingIcon,
			settings: userIcon,
			support: supportIcon,
			logout: logoutIcon
		}

		return (
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className={`${screen == screenName ? 'sidebar_item sidebar_item_active' : 'sidebar_item'}`} onClick={() => { handleScreenChange(screenName) }}>
					<img style={{ width: '16px', height: '16px', marginRight: '12px' }} src={icons[screenName]} />
					{text}
				</div>
			</div>
		)
	}

	const SideBar = () => {
		const navigate = useNavigate()

		return (
			<>
				<div style={{
					fontFamily: 'Rubik',
					fontWeight: '200',
					fontSize: '11pt',
					minWidth: '200px',
					width: '20%',
					maxWidth: '250px',
					boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.55)',
					background: '#eee',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					zIndex: 5
				}}>
					<SideBarRow screenName={'overview'} text={'Dashboard'} />
					<SideBarRow screenName={'documents'} text={'Models'} />
					<SideBarRow screenName={'billing'} text={'Billing'} />
					<SideBarRow screenName={'support'} text={'Support'} />
					<SideBarRow screenName={'settings'} text={'Account'} />
					<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'flex-end' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className={'sidebar_item'} onClick={() => { signOut();navigate("/") }}>
								<img style={{ width: '16px', height: '16px', marginRight: '12px' }} src={logoutIcon} />
								Sign Out
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	const ActiveScreen = () => {
		switch (screen) {
			case 'overview':
				return Overview()
				break
			case 'documents':
				return Documents()
				break
			case 'support':
				return Support()
				break
			case 'settings':
				return Settings()
				break
			case 'billing':
				return Billing()
				break
			default:
				return Overview()
		}
	}
	
	if (!state.user) {
		// attempt sign in
		// return <p>No user</p>
		// return <CenteredLoadingSpinner />
		if(state.user === false){
			return <Navigate replace to="/" />
		} else {
			return null
		}
	}

	if(state.user == 'isLoading'){
		return null
	}

	return (
		<div style={{ display: 'flex', height: '100%' }}>
			<SideBar />
			<div style={{ display: 'flex', overflow: 'auto', width: '100%', height: '100%', padding: '15px', backgroundColor: '#f2f3f3' }}>
				<ActiveScreen />
			</div>
			{/* <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div style={{ boxShadow: '0px 2px 3px 1px rgba(0,0,0,0.15)', width: '40%', height: '400px', padding: '20px' }}>
                        <Chart type='doughnut' data={{
                            labels: ['Vendor 1', 'Vendor 2', 'Vendor 3'],
                            datasets: [
                                {
                                    id: 1,
                                    label: 'Vendors',
                                    data: [3, 6, 1],
                                    backgroundColor: ['#faa', '#afa', '#d0d']
                                },
                                {
                                    id: 2,
                                    label: 'Models',
                                    data: [2, 1, 2, 2, 2, 1],
                                },
                            ],
                        }} />
                    </div>
                    <div style={{ width: '40%', height: '400px' }}>
                        <p>Active Webhooks: {state.data.webhooks}</p>
                        <p>Number of Models: {state.data.models}</p>
                        <p>Number of Vendors: {state.data.vendors}</p>
                        <p>Successful Calls: {state.data.webhook_success}</p>
                        <p>Failed Calls: {state.data.webhook_fail}</p>
                        <p>Document intake count?</p>
                    </div>
                </div>
                <hr />
                <div style={{ width: '86.67%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', height: '50px', background: '#ccc' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p>Button 1</p>
                        <p>Button 2</p>
                    </div>
                    <div>
                        <p>Button 3</p>
                    </div>
                </div>
                <div style={{ width: '86.67%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignSelf: 'center', marginTop: '15px', height: '350px', background: '#555' }}>
                    Table / Icons
                </div> */}

			{/* 
                section that shows current configurations

                section for controls to add / edit / remove ?
                section to list training documents? 

                usage statistics

                //
            
            </div>
            */}
		</div>
	)
}

export default Dashboard