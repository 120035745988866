import React from 'react'
import parsley from './img/parsley_1.png'

import { FormControl, Button } from 'react-bootstrap'

import CenteredLoadingSpinner from './Loader'

function PrivacyPolicy() {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
			<div style={{ fontSize: '18px', fontWeight: 100, background: 'white', width: '8.5in', borderRadius: '4px', boxShadow: '0px 1px 5px 1px rgba(0,0,0,0.15', padding: '0.5in', paddingTop: '0.75in', display: 'flex', flexDirection: 'column' }}>
				<p>At PDF Parsley, we take the privacy of our users seriously and are committed to protecting your personal information. This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. By using our service, you agree to the collection, use and sharing of your personal information as described in this policy.</p>
				<p>Information Collection and Use:</p>
				<ul>
					<li>We collect information from you when you register for an account, use our services, or communicate with us. This may include your name, email address, and any other information you choose to provide.</li>
					<li>We use the information we collect to provide and improve our services, to communicate with you, and to customize your experience.</li>
					<li>We do not sell or share your personal information with third parties, except as required by law or as necessary to provide our services.</li>
				</ul>
				<p>Security:</p>
				<ul>
					<li>We take reasonable steps to protect your personal information from unauthorized access or disclosure. However, no method of transmitting or storing data is completely secure and we cannot guarantee the security of your personal information.</li>
					<li>We encourage you to take steps to protect your own personal information, such as choosing a strong password and keeping it confidential.</li>
				</ul>
				<p>Cookies and Tracking Technologies:</p>
				<ul>
					<li>We use cookies and other tracking technologies to personalize your experience and to collect usage data. You can control the use of cookies through your browser settings.</li>
				</ul>
				<p>Changes to this Policy:</p>
				<ul>
					<li>We may update this policy from time to time to reflect changes to our practices or for other operational, legal or regulatory reasons. We will notify you of any material changes to this policy by posting the new policy on our website.</li>
				</ul>
				<p>Contact Us:</p>
				<ul>
					<li>If you have any questions about this policy or our practices, please contact us at <a href="mailto:support@pdf-parsley.com" target="_new">support@pdf-parsley.com</a>
					</li>
				</ul>
				<p>This policy is effective as of January 1st, 2019.</p>
			</div>
		</div>
	)
}

export default PrivacyPolicy