import React, { useState, useEffect } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import CenteredLoadingSpinner from './Loader'

const SetupForm = () => {
    const stripe = useStripe()
    const elements = useElements()

    // get cards, if any
    // get customer details, show above

    const [errorMessage, setErrorMessage] = useState(null)
    const [showSubmit, setShowSubmit] = useState(false)

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${config.HOST_URL}/dashboard`,
            }
        })

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setShowSubmit(true)
        }, 500)
    }, [stripe])

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>Customer details</div>
            {(!stripe || !elements) && <CenteredLoadingSpinner />}
            {stripe && elements && <form onSubmit={handleSubmit}>
                <PaymentElement />
                {showSubmit && <button className={'btn btn-primary'} disabled={!stripe}>Submit</button>}
                {/* Show error message to your customers */}
                {errorMessage && <div>{errorMessage}</div>}
            </form>}
        </div>
    )
}

export default SetupForm