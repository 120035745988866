import React, { useState } from 'react'

const ToggleEditText = ({ value, type, label }) => {
	const [editing, setEditing] = useState(false)
	const [text, setText] = useState(value)
	const [error, setError] = useState(null)

	const handleSave = () => {
		if (type == 'email') {
			const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			if (!emailRegex.test(text)) {
				setError("Invalid email")
				return
			}
		}
		setError(null)
		setEditing(false)
	}

	const handleCancel = () => {
		setText(value)
		setEditing(false)
	}

	return (
		<div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, minWidth: '100px', width: '100%', marginTop: '10px'}}>
			<div style={{fontWeight: 200, fontSize: '10pt', color: '#555'}}>{label}</div>
			{editing
				? <div style={{display: 'flex', justifyContent: 'space-between'}}>
					<input className={'input-sm'} style={{border: '1px silver solid', borderRadius: '4px', padding: '5px', display: 'flex', flexGrow: 1, marginRight: '5px'}} value={text} onChange={e => setText(e.target.value)} />
					<button style={{marginRight: '5px'}} className={'btn btn-sm btn-outline-success'} onClick={handleSave}>Save</button>
					<button className={'btn btn-sm btn-outline-danger'} style={{marginRight: '5px'}} onClick={handleCancel}>Cancel</button>
					{error && <div style={{ color: 'red' }}>{error}</div>}
				</div>
				: <div style={{display: 'flex', justifyContent: 'space-between'}}>
					<div style={{display: 'flex', alignSelf: 'center', flexGrow: 1, padding: '5px', border: '1px #dddddd solid', borderRadius: '4px', marginRight: '5px', minHeight: '36px'}}>{text}</div>
					<button className={'btn btn-sm btn-outline-secondary'} style={{marginRight: '5px'}} onClick={() => setEditing(true)}>Edit</button>
				</div>
			}
		</div>
	)
}

export default ToggleEditText