import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import SetupForm from './SetupForm'
import { useGlobalState } from './Context'

import config from './config'
import CenteredLoadingSpinner from './Loader'
import { Button } from 'react-bootstrap'
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51LINHoAJJQ25wck9fgsdv2vAzyrunPysUnz7izYV9qCNfvglFytnIKSOP5v9SxutiQAbHHcmJpaHA3WC7QZsLgwn00WW1rlXhR')

const StripeComponent = () => {
    const [state, dispatch] = useGlobalState()
    const [isLoading, setIsLoading] = useState(true)

    const [secret, setSecret] = useState(null)

    const [sendingAddCard, setSendingAddCard] = useState(false)

    useEffect(() => {
        // if !state.user.customer_id
        // show "create billing" forms

        // const loadForm = async () => {
        //     const res = await fetch(`${config.API_URL}/${state.user.user_id}/intent`)
        //     setSecret(res)
        // }

        // if(!secret) {
        //     loadForm()
        // }
        setSecret(state.user.intent_secret)
        setIsLoading(false)
    }, [])


    const handleAddCard = async () => {
        let customer_id = state.user.customer_id
        if(!customer_id){
            await fetch(`${config.API_URL}/${state.user.user_id}/create-customer`).then(async d => {
                let response = await d.json()
                setSendingAddCard(false)
                console.log("response", response)
                dispatch({
                    ...state,
                    user: {
                        ...state.user,
                        customer_id: response
                    }
                })
                return response
            })
        }

        const res = await fetch(`${config.API_URL}/${state.user.customer_id}/${state.user.user_idPayment}/create-intent`).then(async d => {
            let response = await d.json()
            setSendingAddCard(false)
            console.log("response", response)
            return response
        })
    }

    const options = {
        // passing the client secret obtained from the server
        clientSecret: secret, // this is the secret pulled from the intent I think
    }

    if (isLoading) {
        return <CenteredLoadingSpinner />
    }

    // if(!secret){
    //     return (
    //         <>
    //             <p>No customer set up</p>
    //             <Button onClick={handleAddCard} disabled={sendingAddCard}>Add Card</Button>
    //         </>
    //     )
    //     // no customer, show add card thing

    // }

    return (
        <Elements stripe={stripePromise} options={options}>
            <SetupForm />    
        </Elements>
    )
}

export default StripeComponent