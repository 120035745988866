import React, { useEffect, useState } from 'react'

import { inspect } from 'util'
import { GlobalStateProvider, useGlobalState } from './Context'

import CenteredLoadingSpinner from './Loader'
import StripeComponent from './StripeComponent'

import config from './config'

import dashboardIcon from './img/icons/dashboard.png'
import modelIcon from './img/icons/model.png'
import addIcon from './img/icons/add.png'
import chartIcon from './img/icons/chart.png'
import billingIcon from './img/icons/credit_card.png'
import dataIcon from './img/icons/data.png'
import errorIcon from './img/icons/error.png'
import emailIcon from './img/icons/mail.png'
import deleteIcon from './img/icons/remove.png'
import supportIcon from './img/icons/support.png'
import userIcon from './img/icons/user.png'
import logoutIcon from './img/icons/logout.png'

const Billing = () => {
	// show more in depth billing info
	// figure out a way to edit payment info here (hopefully no redirect to stripe is required)
	// show estimated costs at the end of the month and the current cost based on usage
	// maybe at some point create a discount program or something, but that's pretty far in the future if at all

	// show previous bills and compare
	// show more detailed tier info (current tier, current cost per unit listed with other tiers)

	const [state, dispatch] = useGlobalState()
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		const getData = async () => {
			const isLoaded = Object.keys(state.ui_data).filter((key) => key == 'billing').length > 0
			if (isLoaded) {
				setIsLoading(false)
			} else {
				let cards = await fetch(`${config.API_URL}/${state.user.user_id}/billing`).then(async d => await d.json())

				const billing = {
					cards
				}

				console.log('dispatch 7')
				await dispatch({
					...state,
					ui_data: {
						...state.ui_data,
						billing,
					}
				})

				await setIsLoading(false)
			}
		}

		getData()
	}, [])

	if (isLoading) {
		return <CenteredLoadingSpinner />
	} else {
		// if no stripe customer_id on state.user then show create_customer form
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div>Fetch past 6 months worth of charges from stripe, display here</div>
				<StripeComponent />
			</div>
		)
	}

	const billing = state.ui_data.billing

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div>First Last</div>
				<div>Email</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div>Current usage</div>
				<div>Current cost</div>
			</div>
			<div>Next billing date: {billing.billing_end}</div>
			<div>
				Previous bill
				<div>{formatter.format(billing.previous_amount)}</div>
				<div>Current payment on file: {billing.card_ending}</div>
				<div>Billed to {billing.billing_last}, {billing.billing_first}</div>
			</div>
		</div>
	)
}

export default Billing