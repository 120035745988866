import React, { useEffect, useRef, useState } from 'react'
import CenteredLoadingSpinner from './Loader'
import config from './config'

const frameCount = 300
let image_list = new Array(frameCount)
let stored_images = new Array(frameCount)
let complete_images = 0

const ScrollBackground = () => {
	const scroll_frame = useRef()
	const [currentLoaded, setCurrentLoaded] = useState(0)
	const [loadingComplete, setLoadingComplete] = useState(false)
	const [loadingReadyToScroll, setReadyToScroll] = useState(false)
	const [pathKey, setPathKey] = useState(window.innerWidth < window.innerHeight ? 'landing_mobile_60/mobile_bg_' : 'landing_sequence_60/landing_bg_')
	const [imageDomain, setImageDomain] = useState(config.IMAGE_DOMAIN)

	const html = document.documentElement

	const shortPreload = () => {
		for (let i = 0; i < frameCount; i++) {
			const img = new Image()
			if (i % 2 == 0) {
				img.src = `${imageDomain}/img/${pathKey}${(i).toString().padStart(5, '0')}.jpg`
				image_list[i] = img.src
				img.onload = () => {
					complete_images++
					stored_images[i] = img
					if(i > 150){
						setReadyToScroll(true)
					}
				}
				} else {
				setTimeout(() => {
					img.src = `${imageDomain}/img/${pathKey}${(i).toString().padStart(5, '0')}.jpg`
					image_list[i] = img.src
					img.onload = () => {
						complete_images++
						stored_images[i] = img
						if(complete_images > 290){
							setLoadingComplete(true)
						}
					}
				}, 1000)
			}
		}
	}

	useEffect(() => {
		const initialize = async () => {
			console.log("initializing")
			shortPreload()

			const canvas = scroll_frame.current
			const context = canvas.getContext("2d")

			const width = document.documentElement.clientWidth
			const height = document.documentElement.clientHeight

			canvas.width = width
			canvas.height = height

			setInterval(() => {
				const scrollTop = width < height ? html.scrollTop - 400 : html.scrollTop - 150
				const maxScrollTop = html.scrollHeight - (window.innerHeight * 2)
				const scrollFraction = Math.max(0, (scrollTop / maxScrollTop))

				let frameIndex = Math.floor(scrollFraction * frameCount)

				if (stored_images[frameIndex]) {
					window.requestAnimationFrame(() => {
						context.clearRect(0, 0, width, height)
						context.drawImage(stored_images[frameIndex], 0, 0, width, height)
							
						if (!loadingComplete) {
							if (complete_images >= (frameCount - 3) / 2) {
								setLoadingComplete(true)
							} else {
								setCurrentLoaded(Math.ceil((complete_images / frameCount) * 100))
							}
						}
					})
				} else {
					const closestFrame = frameIndex >= 10 && frameIndex < 300 ? frameIndex - (frameIndex % 3) : frameIndex >= 300 ? frameIndex - (frameIndex % 5) : frameIndex

					window.requestAnimationFrame(() => {
						if (stored_images[closestFrame]) {
							context.clearRect(0, 0, width, height)
							context.drawImage(stored_images[closestFrame], width < height ? width / -2 : 0, 0, width, height > width ? width : height)
						}
					})
				}
			}, 10)


		}

		// window.addEventListener('resize', () => {
		// 	setCurrentLoaded(0)
		//  setPathKey(window.innerWidth < window.innerHeight ? 'landing_mobile_60/mobile_bg_' : 'landing_sequence_60/landing_bg_')
		// 	setReadyToScroll(false)
		// 	setLoadingComplete(false)
		// 	image_list = new Array(frameCount)
		// 	stored_images = new Array(frameCount)
		// 	complete_images = 0
		// 	console.log('resize')
		// 	initialize()
		// })

		initialize()
	}, [])

	if (!loadingComplete) {
		return (
			<div style={{ overflow: 'hidden', position: 'fixed', width: '100vw', height: '100vh', background: 'transparent' }}>
				<canvas style={{ opacity: loadingReadyToScroll ? 1 : 0, height: loadingReadyToScroll ? '1080px' : 0 }} ref={scroll_frame}></canvas>
				{!loadingComplete && (
					<div style={{ position: 'fixed', zIndex: 50, bottom: '-40px', width: '150px', height: '150px', right: '-40px' }}>
						<CenteredLoadingSpinner scale={0.7} value={currentLoaded} isProgressBar={true} progress={Math.ceil((complete_images / (frameCount / 2)) * 100)} />
					</div>
				)}
			</div>

		)
	}
	return (
		<div style={{ overflow: 'hidden', position: 'fixed', width: '100vw', height: '100vh', background: 'transparent' }}>
			<canvas style={{ opacity: loadingComplete ? 1 : 0 }} ref={scroll_frame}></canvas>
			{!loadingComplete && <div style={{ position: 'fixed', zIndex: 50, bottom: '-40px', width: '150px', height: '150px', right: '-40px' }}><CenteredLoadingSpinner scale={0.7} value={currentLoaded} /></div>}
		</div>
	)

}

export default ScrollBackground