import React, { useEffect, useState } from 'react'

import { GlobalStateProvider, useGlobalState } from './Context'
import { Chart } from 'react-chartjs-2'

import CenteredLoadingSpinner from './Loader'

import { DateTime } from 'luxon'
import config from './config'
import GenericCard from './GenericCard'

import parseIcon from './img/icons/parse.png'
import computeIcon from './img/icons/compute.png'
import sendIcon from './img/icons/send.png'

const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

const CardCollection = async () => {
	const res = []
	for (let i = 0; i < 11; i++) {
		res.push(<GenericCard title={`Card ${i}`} isDouble={Math.random() * 1 > 0.5 ? true : false} />)
	}
	console.log(res)
	return res
}


const Overview = () => {
	// show current usage, estimated usage at the end of the month
	// maybe do some upselling here - if account doesn't have storage enabled show cost with storage
	// show estimates with higher usage and lower usage


	const [state, dispatch] = useGlobalState()
	const [isLoading, setIsLoading] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)
	const [tab, setTab] = useState('parse')
	const [overview, setOverview] = useState(state.ui_data.overview || false)

	const [cardCollection, setCardCollection] = useState(null)
	const [timeScale, setTimeScale] = useState({parse: 'daily', send: 'daily', store: 'cumulative'})


	// useEffect(() => {
	//     const setDataToState = async () => {

	//     }

	//     setDataToState()
	// }, [overview])

	// const [pricing, setPricing] = useState({...state.tiers})
	// useEffect(() => {
	// 	const setPricing = async () => {
	// 	}

	// 	if(!state.tiers.parse){
	// 		setPricing()
	// 	}
	// }, [pricing])

	useEffect(() => {
		const collect = async () => {
			const cards = await CardCollection()
			setCardCollection(cards)
		}

		collect()
	}, [])

	useEffect(() => {
		const getCurrentPricing = async () => {
			await fetch(`${config.API_URL}/get-pricing`).then(async d => {
				const res = await d.json()
				if (res.status == 'success') {
					const pricing = res.pricing
					const newState = { ...state, tiers: pricing }
					await dispatch({ ...newState })
					// setTimeout(async () => {
					// await setPricing({...pricing})
					// }, 1500)
				} else {
					throw new Error(res.message)
				}
			}).catch((e) => {
				console.log(e)
			})
		}
		if (!state.tiers.parse) {
			getCurrentPricing()
		}
	}, [])


	useEffect(() => {
		const getData = async () => {
			const overview = await fetch(`${config.API_URL}/${state.user.user_id}/usage`).then(async d => d.json())
			// const testingOverview = {...overview}
			// testingOverview.parse.used = Math.floor(Math.random() * 10000)
			// testingOverview.send.used = Math.floor(Math.random() * 10000)
			await dispatch({
				...state,
				ui_data: {
					...state.ui_data,
					overview
				}
			})
			await setIsLoaded(true)
			await setIsLoading(false)
			setOverview(overview)
		}
		if (!overview && !isLoaded && state.user.user_id) {
			getData()
		}
	}, [])

	if (!state.ui_data.overview) {
		return <CenteredLoadingSpinner />
	}

	const data = state.ui_data.overview

	const { totals, parse, send, store } = data

	const parseDataset = {
		label: "Parse",
		data: [],
		fill: true,
		backgroundColor: "rgba(102,202,10,0.3)",
		borderColor: "rgba(60,182,40,1)",
		pointHitRadius: 10,
		pointHoverRadius: 10,
		pointRadius: 0,
		tension: 0.15,
		borderWidth: 1,
	}

	const sendDataset = {
		label: "Send",
		data: [],
		fill: true,
		backgroundColor: "rgba(71, 150, 255, 0.3)",
		borderColor: "rgba(71, 150, 255, 1)",
		pointHitRadius: 10,
		pointHoverRadius: 10,
		pointRadius: 0,
		tension: 0.15,
		borderWidth: 1,
	}

	const storeDataset = {
		label: "Store",
		data: [],
		fill: true,
		backgroundColor: "rgba(149, 83, 255, 0.3)",
		borderColor: "rgba(149, 83, 255, 1)",
		pointHitRadius: 10,
		pointHoverRadius: 10,
		pointRadius: 0,
		tension: 0.15,
		borderWidth: 1,
	}

	const storeDatasetDaily = {
		label: "Store",
		data: [],
		fill: true,
		backgroundColor: "rgba(149, 83, 255, 0.3)",
		borderColor: "rgba(149, 83, 255, 1)",
		pointHitRadius: 10,
		pointHoverRadius: 10,
		pointRadius: 0,
		tension: 0.15,
		borderWidth: 1,
	}

	const parseLabels = data.parse.last_30.map((item, index) => {
		// parseDataset.data.push(value.count)
		parseDataset.data.push(Math.floor(Math.random() * 40))
		return index + 1
	})

	const sendLabels = data.send.last_30.map((item, index) => {
		// sendDataset.data.push(value.count)
		sendDataset.data.push(Math.floor(Math.random() * 40))
		return index + 1
	})

	const storeLabels = data.store.last_30.map((item, index) => {
		// storeDataset.data.push(value.count)
		if (index > 0) {
			storeDataset.data.push(storeDataset.data[index - 1] + (Math.random() * 3))
		} else {
			storeDataset.data.push(Math.floor(Math.random() * 40))
		}
		return index + 1
	})


	const storeLabelsDaily = data.store.last_30.map((item, index) => {
		// storeDataset.data.push(value.count)
		if (index > 0) {
			storeDatasetDaily.data.push((Math.random() * 30))
		} else {
			storeDatasetDaily.data.push(Math.floor(Math.random() * 40))
		}
		return index + 1
	})


	const parseData = {
		labels: parseLabels,
		datasets: [parseDataset]
	}

	const sendData = {
		labels: sendLabels,
		datasets: [sendDataset]
	}

	const storeData = {
		labels: storeLabels,
		datasets: [storeDataset]
	}

	const storeDataDaily = {
		labels: storeLabelsDaily,
		datasets: [storeDatasetDaily]
	}


	const chartOptions = {
		animation: {
			duration: 0
		},
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			// scales: { ????? figure this out later
			//     x: {
			//         min: 0,
			//         max: 1000,
			//         ticks:{
			//             stepSize: 10,
			//         }
			//         // gridLines: {
			//         //     drawBorder: false
			//         // }
			//     }
			// }
		},
	}

	const updateTab = (tabType) => {
		setTab(tabType)
	}

	const date = new Date(Date.now())

	let current_parse_price = '-.--'
	let current_send_price = '-.--'
	let current_store_price = '-.--'
	// if (!pricing.parse) {
	if (!state.tiers || !state.tiers.parse || !state.tiers.send || !state.tiers.store) {
		// return <p>Loading Tiers</p>
		return <CenteredLoadingSpinner />
	}

	if (state.tiers.parse.filter((item, index) => { return item.end > parse.used || (!item.end && parse.used > item.start) }).length) {
		current_parse_price = Number(state.tiers.parse.filter((item, index) => { return item.end > parse.used || (!item.end && parse.used > item.start) })[0].price)
	}
	if (state.tiers.send.filter((item, index) => { return item.end > send.used || (!item.end && send.used > item.start) }).length) {
		current_send_price = Number(state.tiers.send.filter((item, index) => { return item.end > send.used || (!item.end && send.used > item.start) })[0].price)
	}

	if (state.tiers.parse.filter((item, index) => { return item.end > parse.used || (!item.end && parse.used > item.start) }).length) {
		// current_parse_price = Number(state.tiers.parse.filter((item, index) => { return item.end > parse.used || (!item.end && parse.used > item.start)})[0].price)
		current_store_price = 0
	}


	const parse_tier_obj = state.tiers.parse.map((item, index) => {
		const tier_used = parse.used - item.end > item.start ? item.end : Math.max(parse.used - (item.start - 1), 0)
		return { tier_used, end: item.end, start: item.start }
	}).filter((item) => {
		return (item.end && item.tier_used < item.end && item.tier_used > item.start) || (!item.end && item.tier_used > item.start)
	})[0]

	const parse_tier_usage = parse_tier_obj && parse_tier_obj.end && (parse_tier_obj.end - parse_tier_obj.tier_used - (parse_tier_obj.start - 1) > 0) ? parse_tier_obj.end - parse_tier_obj.tier_used - (parse_tier_obj.start - 1) : '---'
	const next_parse_price = 0
	const next_parse_end = 0

	const send_tier_obj = state.tiers.send.map((item, index) => {
		const tier_used = send.used - item.end > item.start ? item.end : Math.max(send.used - (item.start - 1), 0)
		return { tier_used, end: item.end, start: item.start }
	}).filter((item) => {
		return (item.end && item.tier_used < item.end && item.tier_used > item.start) || (!item.end && item.tier_used > item.start)
	})[0]

	// const send_tier_obj = state.tiers.send.map((item, index) => {
	//     const tier_used = send.used - item.end > item.start ? item.end : Math.max(send.used - (item.start - 1), 0)
	//     return { tier_used, end: item.end, start: item.start}
	// }).filter((item) => {
	//     return item.tier_used != item.end
	// })[0]

	const send_tier_usage = send_tier_obj && send_tier_obj.end && (send_tier_obj.end - send_tier_obj.tier_used - (send_tier_obj.start - 1) > 0) ? send_tier_obj.end - send_tier_obj.tier_used - (send_tier_obj.start - 1) : '---'
	// const send_tier_usage = send_tier_obj.end ? send_tier_obj.end - send_tier_obj.tier_used - (send_tier_obj.start - 1) : '---'
	const next_send_price = 0
	const next_send_end = 0

	// const store_tier_obj = state.tiers.store.map((item, index) => {
	//     const tier_used = store.used - item.end > item.start ? item.end : Math.max(store.used - (item.start - 1), 0)
	//     return {tier_used, end: item.end}
	// }).filter((item) => {
	//     return item.tier_used != item.end && item.end
	// })[0]

	// const store_tier_usage = store_tier_obj.end - store_tier_obj.tier_used

	const CostContent = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
				<div style={{ fontWeight: 300, margin: '0px', display: 'flex', alignSelf: 'center', flexDirection: 'column', width: '100%', marginBottom: '10px', padding: '10px' }}>
					<div style={{ gridAutoColumns: '1fr', fontSize: '14pt', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
						<div style={{ display: 'flex', alignSelf: 'flex-start' }}>Parse</div>
						<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
							<div>{formatter.format(Number(totals.parse))}</div>
							<div style={{ color: '#bbbbbb', fontSize: '10pt' }}>{Number(parse.used)}</div>
						</div>
					</div>
					<div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
						<div style={{ display: 'flex', alignSelf: 'flex-start' }}>Send</div>
						<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
							<div>{formatter.format(Number(totals.send))}</div>
							<div style={{ color: '#bbbbbb', fontSize: '10pt' }}>{Number(send.used)}</div>
						</div>
					</div>
					<div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
						<div style={{ display: 'flex', alignSelf: 'flex-start' }}>Store</div>
						<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
							<div>{formatter.format(Number(totals.store))}</div>
							<div style={{ color: '#bbbbbb', fontSize: '10pt' }}>{store.items.toFixed(2)}</div>
						</div>
					</div>
				</div>
				{/* {totals.discount && (
					<div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
					<div>Store</div>
					<div>{formatter.format(Number(totals.discount))}</div>
				</div>
				)} */}
				<div style={{ fontWeight: 300, margin: '0px', display: 'flex', alignSelf: 'center', justifyContent: 'space-between', flexDirection: 'column', width: '100%', marginBottom: '-10px', padding: '10px' }}>
					<div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
						<div>Total</div>
						<div>{formatter.format(Number(totals.parse) + Number(totals.send) + Number(totals.store))}</div>
					</div>
				</div>
			</div>
		)
	}

	const testData = () => {
		return (
			<div style={{ fontWeight: 300, margin: '0px', display: 'flex', alignSelf: 'center', justifyContent: 'space-between', flexDirection: 'column', width: '100%', marginBottom: '10px', padding: '10px' }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
					<div style={{ display: 'flex', alignSelf: 'flex-start' }}><img style={{width: '18px', height: '18px', marginRight: '10px', marginTop: '3px'}} src={computeIcon} alt="Parse" /> Parse</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '70%', minWidth: '400px' }}>{state.tiers.parse ? state.tiers.parse.map((item, index, arr) => {
						const tier_used = parse.used
						const is_between = tier_used <= item.end && tier_used >= item.start
						const is_below = tier_used < item.start
						const is_above = tier_used > item.end
						return (
							<div key={`parse_loading_bar_${index}`} style={{ width: '20%', display: 'flex', textAlign: 'center', flexDirection: 'column', marginBottom: '10px', marginRight: '1px', color: current_parse_price <= item.price ? '#333' : '#aaa' }}>
								<div>${item.price}</div>
								{item.end && is_above && <div style={{ backgroundColor: '#3a3', height: '4px', borderRadius: '2px', width: `100%` }}></div>}
								{(is_between || !item.end) && tier_used != 0 && (
									<div style={{ position: 'relative', height: '4px' }}>
										<div style={{ position: 'absolute', borderRadius: '2px', backgroundColor: '#ccc', height: '4px', width: `100%` }}></div>
										<div style={{ position: 'absolute', borderTopLeftRadius: '2px', borderBottomLeftRadius: '2px', backgroundColor: '#3a3', height: '4px', width: `${(tier_used / item.end) * 100}%` }}></div>
									</div>
								)}
								{tier_used == 0 && item.start == 0 && <div style={{ backgroundColor: '#ccc', borderRadius: '2px', height: '4px', width: `100%` }}></div>}
								{item.end && is_below && <div style={{ backgroundColor: '#ccc', borderRadius: '2px', height: '4px', width: `100%` }}></div>}
								{tier_used > item.end && <div>{item.end}</div>}
								{item.end && tier_used != item.end && tier_used >= item.start && tier_used <= item.end && <div style={{fontWeight: 200, fontSize: '9pt', color: '#999'}}>{tier_used}{item.end ? `/${item.end}` : null}</div>}
								{!item.end && tier_used >= item.start && <div>{tier_used - item.start}</div>}
							</div>
						)
					}) : 'Loading...'}
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
					<div style={{ display: 'flex', alignSelf: 'flex-start' }}><img style={{width: '18px', height: '18px', marginRight: '10px', marginTop: '3px'}} src={sendIcon} alt="Parse" /> Send</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '70%', minWidth: '400px' }}>{state.tiers.send ? state.tiers.send.map((item, index, arr) => {
						const tier_used = send.used
						const is_between = tier_used < item.end && tier_used > item.start
						const is_below = tier_used < item.start
						const is_above = tier_used >= item.end

						return (
							<div key={`send_loading_bar_${index}`} style={{ width: '20%', display: 'flex', textAlign: 'center', flexDirection: 'column', marginBottom: '10px', marginRight: '1px', color: current_store_price <= item.price ? '#333' : '#aaa' }}>
								<div>${item.price > 0 ? item.price.toFixed(3) : 0}</div>
								{item.end && is_above && <div style={{ backgroundColor: '#3a3', height: '4px', width: `100%` }}></div>}
								{(is_between || !item.end) && tier_used != 0 && (
									<div style={{ position: 'relative', height: '4px' }}>
										<div style={{ position: 'absolute', backgroundColor: '#ccc', height: '4px', width: `100%` }}></div>
										<div style={{ position: 'absolute', backgroundColor: '#3a3', height: '4px', width: `${(tier_used / item.end) * 100}%` }}></div>
									</div>
								)}
								{tier_used == 0 && item.start == 0 && <div style={{ backgroundColor: '#ccc', height: '4px', width: `100%` }}></div>}
								{item.end && is_below && <div style={{ backgroundColor: '#ccc', height: '4px', width: `100%` }}></div>}
								{tier_used > item.end && <div>{item.end}</div>}
								{item.end && tier_used != item.end && tier_used >= item.start && tier_used <= item.end && <div style={{fontWeight: 200, fontSize: '9pt', color: '#999'}}>{tier_used}{item.end ? `/${item.end}` : null}</div>}
								{!item.end && tier_used >= item.start && <div>{tier_used - item.start}</div>}
							</div>
						)
					}) : 'Loading...'}
					</div>

				</div>
			</div>
		)
	}

	const parseChart = ({displayType='daily'}) => {
		return (
			<div style={{ fontWeight: 300, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'column', borderRight: ' 1px silver solid', paddingRight: '15px', minWidth: '200px' }}>
					<div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
						<h4>Parse</h4>
						<div style={{ textAlign: 'right', color: '#777777' }}>
							<h4 style={{ display: 'flex', fontWeight: 400 }}>{formatter.format(totals.parse)}</h4>
							<div style={{ fontSize: '10pt' }}>{parse.used} parses</div>
						</div>
					</div>
					<div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
						<div>
							<div style={{ fontSize: '18pt', fontWeight: 400 }}>Current Tier</div>
							<div>{parse_tier_usage} remaining at <span style={{ color: '#797' }}>${current_parse_price}</span> per request</div>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', maxWidth: '80%', width: '80%', height: '250px', paddingLeft: '15px', paddingRight: '15px' }}>
					<p>Last 30 days</p>
					<div style={{ display: 'flex', flexGrow: 1, alignContent: 'center', marginBottom: '5px' }}>
						<Chart type='line' data={parseData} options={chartOptions} />
					</div>
				</div>
			</div>
		)
	}

	const sendChart = ({displayType='daily'}) => {
		return (
			<div style={{ fontWeight: 300, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'column', borderRight: ' 1px silver solid', paddingRight: '15px', minWidth: '200px' }}>
					<div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
						<h4>Send</h4>
						<div style={{ textAlign: 'right', color: '#777777' }}>
							<h4 style={{ display: 'flex', fontWeight: 400 }}>{formatter.format(totals.send)}</h4>
							<div style={{ fontSize: '10pt' }}>{send.used} webhooks</div>
						</div>
					</div>
					<div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
						<div>
							<div style={{ fontSize: '18pt', fontWeight: 400 }}>Current Tier</div>
							<div>{send_tier_usage} remaining at <span style={{ color: '#57b' }}>${current_send_price}</span> per request</div>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', maxWidth: '80%', width: '80%', height: '250px', paddingLeft: '15px', paddingRight: '15px' }}>
					<p>Last 30 days</p>
					<div style={{ display: 'flex', flexGrow: 1, alignContent: 'center' }}>
						<Chart type='line' data={sendData} options={chartOptions} />
					</div>
				</div>
			</div>)
	}

	const storeChart = ({displayType='cumulative'}) => {
		return (
			<div style={{ fontWeight: 300, display: 'flex', flexDirection: 'row', padding: '15px', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'column', borderRight: ' 1px silver solid', paddingRight: '15px', minWidth: '200px' }}>
					<div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
						<h4>Store</h4>
						<h4 style={{ display: 'flex', color: '#777777', fontWeight: 400 }}>
							{formatter.format(totals.store)}
						</h4>
					</div>
					<div style={{ fontSize: '14pt' }}>
						<div>{store.items} items stored ({store.total_size.toFixed(5)})</div>
						<span style={{ color: '#88c' }}>${current_store_price}</span> / GB
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', maxWidth: '80%', width: '80%', height: '250px', paddingLeft: '15px', paddingRight: '15px' }}>
					<p>Last 30 days</p>
					<div style={{ display: 'flex', flexGrow: 1, alignContent: 'center' }}>
						{displayType == 'cumulative' && <Chart type='line' data={storeData} options={chartOptions} />}
						{displayType == 'daily' && <Chart type='line' data={storeDataDaily} options={chartOptions} />}
					</div>
				</div>
			</div>
		)
	}

	const TimeToggle = ({key}) => {
		const newTimeScale = {...timeScale}
		newTimeScale[key] = timeScale[key] == 'daily' ? 'cumulative' : 'daily'

		const handleClick = () => {
			setTimeScale({...newTimeScale})
		}

		return (
			<div style={{marginRight: '5px', color: '#4d9aff'}}>
				<div style={{ fontWeight: 300, textTransform: 'capitalize', cursor: 'pointer'}} onClick={handleClick}>{timeScale[key]}</div>
			</div>
		)
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '0px #ddd solid', padding: '15px', margin: '-15px', marginBottom: '15px', boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.15)' }}>
					<div style={{ fontSize: '18pt', fontWeight: 300, paddingLeft: '10px' }}>Dashboard</div>
					<div style={{ fontWeight: 300 }}>Updated at {date.toLocaleString(DateTime.DATETIME_MED)}</div>
				</div>

				{/* <div className={'usage_header'} style={{ margin: '10px', display: 'flex', alignSelf: 'center', justifyContent: 'space-between', flexDirection: 'column', width: '100%', marginBottom: '10px', padding: '10px' }}>
                    <div style={{ fontSize: '16pt', fontWeight: 400, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '-10px', padding: '10px' }}>
                        <div>{Months[date.getMonth()]} Total</div>
                        <div>{formatter.format(Number(totals.parse) + Number(totals.send) + Number(totals.store))}</div>
                    </div>
                </div> */}
				<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
					<GenericCard title={`${Months[date.getMonth()]} Cost Breakdown`} isDouble={false} content={CostContent()} />
					<GenericCard title={`${Months[date.getMonth()]} Top Vendors`} isDouble={false} />
					<GenericCard isDouble content={testData()} />
					<GenericCard isDouble content={parseChart({displayType: timeScale['parse']})} toggle={TimeToggle({key: 'parse'})} />
					<GenericCard isDouble content={sendChart({displayType: timeScale['send']})} toggle={TimeToggle({key: 'send'})} />
					<GenericCard isDouble content={storeChart({displayType: timeScale['store']})} toggle={TimeToggle({key: 'store'})} />
					{/* {cardCollection} */}
				</div>

				{/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', borderBottom: '1px silver solid' }}>
                    <div className={`usage_tab_button ${tab == 'parse' ? 'usage_tab_active' : ''}`} onClick={() => updateTab('parse')}>Parse</div>
                    <div className={`usage_tab_button ${tab == 'send' ? 'usage_tab_active' : ''}`} onClick={() => updateTab('send')}>Send</div>
                    <div className={`usage_tab_button ${tab == 'store' ? 'usage_tab_active' : ''}`} onClick={() => updateTab('store')}>Store</div>
                </div> */}
				{/* {tab == 'parse' && (<div className={'usage_container'} style={{ display: 'flex', flexDirection: 'row', padding: '15px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: ' 1px silver solid', paddingRight: '15px', minWidth: '200px' }}>
                        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
                            <h4>Parse</h4>
                            <div style={{ textAlign: 'right', color: '#777777' }}>
                                <h4 style={{ display: 'flex', fontWeight: 400 }}>{formatter.format(totals.parse)}</h4>
                                <div style={{ fontSize: '10pt' }}>{parse.used} parses</div>
                            </div>
                        </div>
                        <div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                            <div>
                                <div style={{ fontSize: '18pt', fontWeight: 400 }}>Current Tier</div>
                                <div>{parse_tier_usage} remaining at <span style={{ color: '#797' }}>${current_parse_price}</span> per request</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '80%', width: '80%', height: '250px', paddingLeft: '15px', paddingRight: '15px' }}>
                        <p>Last 30 days</p>
                        <div style={{ display: 'flex', flexGrow: 1, alignContent: 'center' }}>
                            <Chart type='line' data={parseData} options={chartOptions} />
                        </div>
                    </div>
                </div>)}
                {tab == 'send' && (<div className={'usage_container'} style={{ display: 'flex', flexDirection: 'row', padding: '15px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: ' 1px silver solid', paddingRight: '15px', minWidth: '200px' }}>
                        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
                            <h4>Send</h4>
                            <div style={{ textAlign: 'right', color: '#777777' }}>
                                <h4 style={{ display: 'flex', fontWeight: 400 }}>{formatter.format(totals.send)}</h4>
                                <div style={{ fontSize: '10pt' }}>{send.used} webhooks</div>
                            </div>
                        </div>
                        <div style={{ fontSize: '14pt', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                            <div>
                                <div style={{ fontSize: '18pt', fontWeight: 400 }}>Current Tier</div>
                                <div>{send_tier_usage} remaining at <span style={{ color: '#797' }}>${current_send_price}</span> per request</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '80%', width: '80%', height: '250px', paddingLeft: '15px', paddingRight: '15px' }}>
                        <p>Last 30 days</p>
                        <div style={{ display: 'flex', flexGrow: 1, alignContent: 'center' }}>
                            <Chart type='line' data={sendData} options={chartOptions} />
                        </div>
                    </div>
                </div>)}
                {tab == 'store' && (<div className={'usage_container'} style={{ display: 'flex', flexDirection: 'row', padding: '15px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: ' 1px silver solid', paddingRight: '15px', minWidth: '200px' }}>
                        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
                            <h4>Store</h4>
                            <h4 style={{ display: 'flex', color: '#777777', fontWeight: 400 }}>
                                {formatter.format(totals.store)}
                            </h4>
                        </div>
                        <div style={{ fontSize: '14pt' }}>
                            <div>{store.items} items stored ({store.total_size.toFixed(5)})</div>
                            <span style={{ color: '#797' }}>${current_store_price}</span> / GB
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '80%', width: '80%', height: '250px', paddingLeft: '15px', paddingRight: '15px' }}>
                        <p>Last 30 days</p>
                        <div style={{ display: 'flex', flexGrow: 1, alignContent: 'center' }}>
                            <Chart type='line' data={storeData} options={chartOptions} />
                        </div>
                    </div>
                </div>)} */}
			</div>
		</div>
	)
}

export default Overview