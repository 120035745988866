import React from 'react'
import CenteredLoadingSpinner from './Loader'
import { useGlobalState } from './Context'

const Demo = () => {
	const [state, dispatch] = useGlobalState()

	if (!state.user) {
		return <div>Sign in with your provided application credentials to view the PDF Parsley Demo.</div>
	}
	if (state.user === 'isLoading') {
		return <CenteredLoadingSpinner />
	}

	if (state.user && state.user != 'isLoading') {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{ margin: '10px' }}>
					<div style={{ fontSize: '18pt', fontWeight: 300 }}>PDF Parsley - Demo</div>
					<div style={{ width: '640px', height: '480px', borderRadius: '2px', boxShadow: '0px 1px 2px 1px rgba(0,0,0,0.15)' }}></div>
				</div>
				<div style={{ fontWeight: 300, width: '640px', padding: '20px', boxShadow: '0px 1px 2px 1px rgba(0,0,0,0.15)', borderRadius: '4px' }}>
					<h4>PDF Parsley is the best flavor of dumb technology.</h4><br />
					<h5>What it is</h5>
					<p>It's a cloud based document parser that users train to automate their document intake and data extraction.</p><br />
					
					<h5>What it's not</h5>
					<p>It's not some Machine Learning, Natural Language Processing, or AI Preditive model that learns and suggests drink choices with your data extraction. Our core services are simple and easy to extend.</p><br />

					<h5>The Gist</h5>
					<p>
						We have users upload a real document, something that they would normally process themselves. We ask them to select and name parts of the document and offer a few different ways to do so.<br /><br />

						The user trains our platform by creating individual <span style={{fontWeight: 500}}>rules</span> that comprise a <span style={{fontWeight:500}}>model</span> when combined with identifying information about the document. Between the information they provide and the information they specify in the rules, the platform is able to pick and choose how and where to apply models.<br /><br />

						When new documents come in matching a specific model, the rules are applied to the document and data is extracted.
						We take that data, store a representative object on a company specific table, and if the user specifies during Company Onboarding, we send the data via POST in JSON format to a webhook they specify.<br /><br />
						We also plan to offer document storage, though I'm not sure how valueable it would be.
					</p><br />

					<h5>Current functionality and limitations</h5>
					<div>
						<h6>Backend</h6>
						<ul>
							<span style={{color: 'silver', fontSize: '10pt'}}>Implemented</span>
							<li style={{color: '#339933'}}>Coordinate based</li>
							<li style={{color: '#339933'}}>String delimited search</li>
						</ul>
						<ul>
							<span style={{color: 'silver', fontSize: '10pt'}}>In Progress</span>
							<li style={{color: '#993333'}}>String delimited with vertical bounds - column search</li>
							<li style={{color: '#993333'}}>String delimited with horizontal bounds - row search</li>
						</ul>
						<ul>
							<span style={{color: 'silver', fontSize: '10pt'}}>Planned</span>
							<li style={{color: '#993333'}}>Tabular grid - table parsing</li>
							<li style={{color: '#993333'}}>Image Processing - OCR via tesseract</li>
						</ul>
						<br />
						<h6>Frontend</h6>
						<ul>
							<span style={{color: 'silver', fontSize: '10pt'}}>Implemented</span>
							<li style={{color: '#339933'}}>Landing page</li>
							<li style={{color: '#339933'}}>Sign up flow</li>
							<li style={{color: '#339933'}}>Auth layer</li>
							<li style={{color: '#339933'}}>Model creator</li>
							<li style={{color: '#339933'}}>Dashboard proof of concept</li>
							<li style={{color: '#339933'}}>Usage based billing</li>

						</ul>
						<ul>
							<span style={{color: 'silver', fontSize: '10pt'}}>In Progress</span>
							<li style={{color: '#993333'}}>Live data in remaining components</li>
							<li style={{color: '#993333'}}>Billing changes via parsley platform</li>
						</ul>
						<ul>
							<span style={{color: 'silver', fontSize: '10pt'}}>Planned</span>
							<li style={{color: '#993333'}}>Multi-page optimizations</li>
							<li style={{color: '#993333'}}>Mobile app for image upload / document "scanning"</li>
						</ul>
					</div><br />

					<h5>Why not slap some AI on this thing?</h5>
					<p>
						First, it's easier to build, hire for, and extend this way.<br /><br />
						Second, its cheaper to run than some crazy AI NLP ML neural net that lives on some big ol' EC2 instance.<br /><br />
						Third, its fast. Resources can be applied or removed without affecting the service.
					</p><br /><br />

					<p>To really drive the point home, nobody wants to manage a scalable network of AI NLP ML pipelines. It's much easier to configure cloud functions with higher concurrency, its much cheaper to run a function for a few seconds than a server 24/7, and being serverless it's immediately available since there's no spin-up or spin-down periods to apply updates or attempt to save on running costs.</p>
				</div>
			</div>
		)
	}
}

export default Demo