// Import FirebaseAuth and firebase.
import React, { useEffect, useState, useSelector } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import parsley from './img/parsley_1.png'

// import firebaseApp from './firebase'
import { Link, Navigate, Redirect, useRoutes } from 'react-router-dom'
import { GlobalStateProvider, useGlobalState } from './Context'

import { FormControl, Button } from 'react-bootstrap'

import green_1 from './img/green_1.png'
import blue_1 from './img/blue_1.png'

import config from './config'
import CenteredLoadingSpinner from './Loader'
// Configure Firebase.

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    displayMode: 'optionFirst',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
}

function SignUp() {
    const [state, dispatch] = useGlobalState()
    const [show, setShow] = useState(false)
    const [signUp, setSignUp] = useState({
        first: '',
        last: '',
        email: state.sign_up_email ? state.sign_up_email : '',
        company: '',
        password: '',
        password_confirm: ''
    })
    const [errors, setShowErrors] = useState({
        first: false,
        last: false,
        email: false,
        company: false,
        password: false,
        password_confirm: false
    })
    const [error, setError] = useState(false)

    const [triggerSignUp, setTriggerSignUp] = useState(false)
    
    const [isSigningUp, setIsSigningUp] = useState(false)
    
    useEffect(() => {
        const send = async () => {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(signUp)
            }
            setIsSigningUp(true)
            await fetch(`${config.API_URL}/sign-up`, options).then(async d => {
                const data = await d.json()
                console.log(data)
                if(data.status && data.status == 'error'){
                    setError(data.message)
                    return
                }

                let user = data.user
                localStorage.setItem('custom_token', user.custom_token)
                console.log('dispatch 32')
                await dispatch({ ...state, user })
            })
            setIsSigningUp(false)
        }

        if (triggerSignUp) {
            send()
        }
    }, [triggerSignUp])

    // const Navigate = useNavigate()
    // const { isLoggedIn } = useSelector((state) => state.auth);

    // const routing = useRoutes(routes(isLoggedIn));

    // const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    // const navigate = useNavigate()
    // Listen to the Firebase Auth state and set the local state.

    // useEffect(() => {
    //     if (state.user && state.user != 'isLoading') {
    //         firebase.auth().signOut()
    //         dispatch({ ...state, user: 'isLoading' })
    //     }
    // }, [])


    useEffect(() => {
        // read custom token from local storage to log users back in when they visit again
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async user => {
            // fetch aws_user from backend
            console.log(user)
            const u = JSON.parse(JSON.stringify(user))
            const auth_token = u.stsTokenManager.accessToken
            const options = {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${auth_token}`
                }
            }

            const aws_user = await fetch(`${config.API_URL}/get_user`, options).then(user => user.json())
            
            console.log('dispatch 33')
            await dispatch({ ...state, user: aws_user })
        })
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 200)
    }, [])


    const handleSignUpChange = (e, path) => {
        const newErrors = { ...errors }
        newErrors[path] = false
        setShowErrors(newErrors)

        const newSignUp = { ...signUp }
        newSignUp[path] = e.target.value
        setSignUp(newSignUp)
    }

    const handleSubmit = async () => {
        // check fields are filled
        console.log('checking errors')
        const newErrors = { ...errors }

        if (signUp.first.length < 1) {
            newErrors.first = true
        }
        if (signUp.last.length < 1) {
            newErrors.last = true
        }
        if (signUp.email.length < 6 || signUp.email.indexOf('@') == -1) {
            newErrors.email = true
        }
        if (!signUp.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/)) {
            newErrors.password = true
        }
        if (signUp.password_confirm != signUp.password || signUp.password_confirm.length < 8) {
            newErrors.password_confirm = true
        }

        await setShowErrors(newErrors)

        if (Object.values(newErrors).filter((item) => item).length == 0) {
            // has 0 errors
            setTriggerSignUp(Math.random())
        }
    }

    const password_invalid = !signUp.password.match(/^(?=.*[A-Z])/) ||
    !signUp.password.match(/^(?=.*[a-z])/) ||
    !signUp.password.match(/^(?=.*[0-9])/) ||
    !signUp.password.match(/^(?=.*[@#$%^&+!=])/) || signUp.password != signUp.password_confirm

    if (!state.user || state.user == 'isLoading') {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: (show ? '#83fbff' : '#333'), transition: '0.4s', alignContent: 'center', justifyContent: 'center', position: 'fixed', width: '100%', height: '100%', overflow: 'hidden' }}>
                    <div style={{ zIndex: 3, display: 'flex', flexDirection: 'column', backgroundColor: 'white', textAlign: 'center', justifySelf: 'center', alignSelf: 'center', width: '400px', borderRadius: '4px', opacity: (show ? 1 : 0), transition: '0.4s', boxShadow: `0px 2px 4px 2px rgba(0, 0, 0, ${(show ? 0.35 : 0)})` }}>
                        <div style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignContent: 'center', color: '#7ade8c' }}>
                            <div style={{ backgroundImage: `url(${parsley})`, backgroundSize: 'contain', width: '70px', height: '70px', backgroundRepeat: 'no-repeat' }}></div>
                            {/* <img src={parsley} width={'40px'} height={'40px'} style={{ border: '2px red solid', marginRight: '30px'}}/> */}
                            <h1 style={{ display: 'flex', alignSelf: 'center', marginTop: '20px', fontWeight: 400 }}>Parsley</h1>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '15px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormControl aria-label="Key" placeholder={'First Name'} style={{ borderColor: errors.first ? 'red' : 'silver', width: '49%', marginBottom: '5px', fontWeight: 200 }} value={signUp.first} onChange={(e) => handleSignUpChange(e, 'first')} aria-describedby="inputGroup-sizing-sm" />
                                <FormControl aria-label="Key" placeholder={'Last Name'} style={{ borderColor: errors.last ? 'red' : 'silver', width: '49%', marginBottom: '5px', fontWeight: 200 }} value={signUp.last} onChange={(e) => handleSignUpChange(e, 'last')} aria-describedby="inputGroup-sizing-sm" />
                            </div>
                            <FormControl aria-label="company" type="text" placeholder={'Company Name (Optional)'} autoComplete="off" style={{ marginBottom: '5px', fontWeight: 200 }} aria-describedby="inputGroup-sizing-sm" value={signUp.company} onChange={(e) => handleSignUpChange(e, 'company')} />
                            <FormControl aria-label="email" type="email" placeholder={'Email Address'} autoComplete="off" style={{ borderColor: errors.email ? 'red' : 'silver', marginBottom: '5px', fontWeight: 200 }} aria-describedby="inputGroup-sizing-sm" value={signUp.email} onChange={(e) => handleSignUpChange(e, 'email')} />
                            <FormControl aria-label="password" type="password" placeholder={'Password'} autoComplete="off" style={{ borderColor: errors.password ? 'red' : 'silver', marginBottom: '5px', fontWeight: 200 }} aria-describedby="inputGroup-sizing-sm" value={signUp.password} onChange={(e) => handleSignUpChange(e, 'password')} />
                            <FormControl aria-label="password_confirm" type="password" placeholder={'Confirm Password'} autoComplete="off" style={{ borderColor: errors.password_confirm ? 'red' : 'silver', marginBottom: '5px', fontWeight: 200 }} aria-describedby="inputGroup-sizing-sm" value={signUp.password_confirm} onChange={(e) => handleSignUpChange(e, 'password_confirm')} />

                            <div style={{ textAlign: 'left', fontSize: '11pt', display: Object.values(errors).filter((item) => item).length == 0 ? 'hidden' : 'visible' }}>
                                <div style={{ fontWeight: 200, display: 'flex', flexDirection: 'column', height: password_invalid ? '100%' : '0%', marginTop: password_invalid ? '0%' : '-100%', transition: 'height 0.5s, margin 0.2s', overflow: 'hidden' }}>
                                    <div style={{ textAlign: 'left' }}>Password must contain:</div>
                                    <ul>
                                        <li><span style={{transition: '0.2s', color: !signUp.password.match(/^(?=.*[A-Z])/) ? 'red' : 'green'}}>An Uppercase Letter</span></li>
                                        <li><span style={{transition: '0.2s', color: !signUp.password.match(/^(?=.*[a-z])/) ? 'red' : 'green'}}>A Lowercase Letter</span></li>
                                        <li><span style={{transition: '0.2s', color: !signUp.password.match(/^(?=.*[0-9])/) ? 'red' : 'green'}}>A Number</span></li>
                                        <li><span style={{transition: '0.2s', color: !signUp.password.match(/^(?=.*[@#$%^&+!=])/) ? 'red' : 'green'}}>A Symbol</span></li>
                                        <li><span style={{transition: '0.2s', color: signUp.password.length < 8 ? 'red' : 'green'}}>8 Characters</span></li>
                                        <li><span style={{transition: '0.2s', color: signUp.password != signUp.password_confirm ? 'red' : 'green'}}>Passwords match</span></li>
                                    </ul>
                                </div>
                                {error && <div style={{padding: '10px', marginBottom:'10px', borderRadius: '4px', border: '1px red solid', color: '#cc0000', background: '#ffeeee'}}><h5>Uh-Oh!</h5><p>{error}</p></div>}
                            </div>

                            {isSigningUp ? <CenteredLoadingSpinner /> : <Button variant={"outline-secondary"} onClick={handleSubmit}>Sign Up</Button>}
                            <hr />
                            <div>Already have an account? <span style={{marginLeft: '10px'}}><Link to="/sign-in">Sign In</Link></span></div>
                        </div>
                        {/* <StyledFirebaseAuth className={'firebase_signin'} uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> */}
                    </div>
                    <img style={{opacity: (show ? 1 : 0), transition: '0.4s', position: 'absolute', zIndex: 1, bottom: '0px', right: '0px', width: '80%%'}} src={green_1} />
                    <img style={{opacity: (show ? 1 : 0), transition: '0.4s', position: 'absolute', zIndex: 1, top: '0px', left: '0px', width: '100%'}} src={blue_1} />
                </div>
            </>
        )
    } else {
        // maybe add a check here to see if the user is new, if so point them to an intake form
        // if not, point them to the dashboard
        return <Navigate to="/dashboard" />
        // return window.location = "/dashboard"
    }
}

export default SignUp