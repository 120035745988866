// Import FirebaseAuth and firebase.
import React, { useEffect, useState, useSelector } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import parsley from './img/parsley_1.png'

import { Navigate } from 'react-router-dom'
import { useGlobalState } from './Context'

import { FormControl, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import green_1 from './img/green_1.png'
import blue_1 from './img/blue_1.png'

import config from './config'
import CenteredLoadingSpinner from './Loader'

function SignIn() {
    const [state, dispatch] = useGlobalState()
    const [show, setShow] = useState(false)
    const [signIn, setSignIn] = useState({
        email: '',
        password: ''
    })
    const [errors, setShowErrors] = useState({
        email: false,
        password: false
    })

    const [triggerSignIn, setTriggerSignIn] = useState(false)
    const [isSigningIn, setIsSigningIn] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        const send = async () => {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(signIn) // should be email / password
            }
            setIsSigningIn(true)
            await fetch(`${config.API_URL}/sign-in`, options).then(async d => {
                const data = await d.json()
                console.log(data)
                if(data.status && data.status == 'error'){
                    setError(data.message)
                    return
                }

                const user = data.user
                user.last_refreshed = Date.now()
                
                localStorage.setItem('custom_token', user.custom_token)
                localStorage.setItem('refresh_token', user.refresh_token)
                console.log('dispatch 31')
                await dispatch({ ...state, user })
            })
            setIsSigningIn(false)
        }

        if (triggerSignIn) {
            send()
        }
    }, [triggerSignIn])

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 200)
    }, [])


    const handleSignUpChange = (e, path) => {
        const newErrors = { ...errors }
        newErrors[path] = false
        setShowErrors(newErrors)

        const newSignIn = { ...signIn }
        newSignIn[path] = e.target.value
        setSignIn(newSignIn)
    }

    const handleSubmit = async () => {
        // check fields are filled
        console.log('checking errors')
        const newErrors = { ...errors }

        if (signIn.email.length < 6 || signIn.email.indexOf('@') == -1) {
            newErrors.email = true
        }

        await setShowErrors(newErrors)

        if (Object.values(newErrors).filter((item) => item).length == 0) {
            // has 0 errors
            setTriggerSignIn(Math.random())
        }
    }

    if (!state.user || state.user == 'isLoading') {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: (show ? '#83fbff' : '#333'), transition: '0.4s', alignContent: 'center', justifyContent: 'center', position: 'fixed', width: '100%', height: '100%', overflow: 'hidden' }}>
                    <div style={{ zIndex: 3, display: 'flex', flexDirection: 'column', backgroundColor: 'white', textAlign: 'center', justifySelf: 'center', alignSelf: 'center', width: '400px', borderRadius: '4px', opacity: (show ? 1 : 0), transition: '0.4s', boxShadow: `0px 2px 4px 2px rgba(0, 0, 0, ${(show ? 0.35 : 0)})` }}>
                        <div style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignContent: 'center', color: '#7ade8c' }}>
                            <div style={{ backgroundImage: `url(${parsley})`, backgroundSize: 'contain', width: '70px', height: '70px', backgroundRepeat: 'no-repeat' }}></div>
                            <h1 style={{ display: 'flex', alignSelf: 'center', marginTop: '20px', fontWeight: 400 }}>Parsley</h1>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '15px' }}>
                            <FormControl aria-label="email" type="email" placeholder={'Email Address'} autoComplete="off" style={{ borderColor: errors.email ? 'red' : 'silver', marginBottom: '5px', fontWeight: 200 }} aria-describedby="inputGroup-sizing-sm" value={signIn.email} onChange={(e) => handleSignUpChange(e, 'email')} />
                            <FormControl aria-label="password" type="password" placeholder={'Password'} autoComplete="off" style={{ borderColor: errors.password ? 'red' : 'silver', marginBottom: '5px', fontWeight: 200 }} aria-describedby="inputGroup-sizing-sm" value={signIn.password} onChange={(e) => handleSignUpChange(e, 'password')} />

                            <div style={{ textAlign: 'left', fontSize: '11pt', display: Object.values(errors).filter((item) => item).length == 0 ? 'hidden' : 'visible' }}>
                            </div>
                            
                            {error && <div style={{padding: '10px', marginBottom:'10px', borderRadius: '4px', border: '1px red solid', color: '#cc0000', background: '#ffeeee'}}><h5>Uh-Oh!</h5><p>{error}</p></div>}
                            {isSigningIn ? <CenteredLoadingSpinner scale={0.5} /> : <Button variant={"outline-secondary"} onClick={handleSubmit}>Sign In</Button>}
                            
                            <hr />
                            <div>Don't have an account? <span style={{marginLeft: '10px'}}><Link to="/sign-up">Sign Up</Link></span></div>
                        </div>
                    </div>
                    <img style={{opacity: (show ? 1 : 0), transition: '0.4s', position: 'absolute', zIndex: 1, bottom: '0px', right: '0px', width: '80%%'}} src={green_1} />
                    <img style={{opacity: (show ? 1 : 0), transition: '0.4s', position: 'absolute', zIndex: 1, top: '0px', left: '0px', width: '100%'}} src={blue_1} />
                </div>
            </>
        )
    } else {
        // maybe add a check here to see if the user is new, if so point them to an intake form
        // if not, point them to the dashboard
        return <Navigate to="/dashboard" />
        // return window.location = "/dashboard"
    }
}

export default SignIn