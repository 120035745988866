import React, { useEffect, useState } from 'react'

import { inspect } from 'util'
import { GlobalStateProvider, useGlobalState } from './Context'

import CenteredLoadingSpinner from './Loader'

import config from './config'


import dashboardIcon from './img/icons/dashboard.png'
import modelIcon from './img/icons/model.png'
import addIcon from './img/icons/add.png'
import chartIcon from './img/icons/chart.png'
import billingIcon from './img/icons/credit_card.png'
import dataIcon from './img/icons/data.png'
import errorIcon from './img/icons/error.png'
import emailIcon from './img/icons/mail.png'
import deleteIcon from './img/icons/remove.png'
import supportIcon from './img/icons/support.png'
import userIcon from './img/icons/user.png'
import logoutIcon from './img/icons/logout.png'

import ToggleEditText from './ToggleEditText'

const Settings = () => {
	// let the user change their settings here
	// enable / disable storage
	// set limits on their accounts
	// show the 'delete account' ccpa stuff here
	// show the 'request all info' gdpr stuff here

	// need to post these settings to the user table in dynamodb
	// need to read from user table to get settings

	const [state, dispatch] = useGlobalState()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const getData = async () => {
			const isLoaded = Object.keys(state.ui_data).filter((key) => key == 'settings').length > 0
			if (isLoaded) {
				setIsLoading(false)
			} else {
				let data = await fetch(`${config.API_URL}/${state.user.user_id}/settings`).then(async d => await d.json())
				console.log('dispatch 4')
				await dispatch({
					...state,
					ui_data: {
						...state.ui_data,
						settings: data.settings,
					}
				})
				await setIsLoading(false)
			}
		}

		getData()
	}, [])


	if (isLoading) {
		return <CenteredLoadingSpinner />
	}

	const {
		first_name,
		last_name,
		email
	} = state.user
	const {
		company,
		enable_storage,
		enable_send,
		storage_type
	} = state.ui_data.settings

	const today = new Date()
	const currentHour = today.getHours()
	
	let greeting
	
	if (currentHour >= 5 && currentHour < 12) {
	  greeting = "Good morning"
	} else if (currentHour >= 12 && currentHour < 17) {
	  greeting = "Good afternoon"
	} else {
	  greeting = "Good evening"
	}

	return (
		<>
			{isLoading && <CenteredLoadingSpinner />}
			{!isLoading && (
				<div style={{ fontWeight: 300, display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{fontWeight: 300, fontSize: '18pt'}}>{greeting}, {first_name}</div>
						<hr />
						<div style={{boxShadow: '0px 2px 4px 1px rgba(0,0,0,0.15)', padding: '10px', borderRadius: '4px', marginBottom: '10px'}}>
							<div style={{fontWeight: 300, fontSize: '14pt'}}>Contact Information</div>
							<div style={{ display: 'flex', flexDirection: 'column', minWidth: '400px', width: '50%'}}>
								<ToggleEditText value={state.user.email} type='email' label={"Email"} />
								<div style={{display: 'flex', justfifyContent: 'space-between'}}>
									<ToggleEditText value={state.user.first_name} label={"First Name"} />
									<ToggleEditText value={state.user.last_name} label={"Last Name"} />
								</div>
								<ToggleEditText value={state.user.company} label={"Company"} />
							</div>
						
						</div>
						<div style={{boxShadow: '0px 2px 4px 1px rgba(0,0,0,0.15)', padding: '10px', borderRadius: '4px'}}>
							<div style={{fontWeight: 300, fontSize: '14pt'}}>Service Settings</div>
							<div style={{ display: 'flex', flexDirection: 'column', minWidth: '400px', width: '50%'}}>
								<ToggleEditText value={state.user.email} type='email' label={"Email"} />
								<div style={{display: 'flex', justfifyContent: 'space-between'}}>
									<ToggleEditText value={state.user.first_name} label={"First Name"} />
									<ToggleEditText value={state.user.last_name} label={"Last Name"} />
								</div>
								<ToggleEditText value={state.user.company} label={"Company"} />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Settings
