import React from 'react'

const GenericCard = ({ title, isDouble, content = false, toggle = null }) => {
	console.log(toggle)

	if (!content) {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', width: isDouble ? 'calc(50% - 10px)' : 'calc(25% - 10px)', height: '300px', minWidth: isDouble ? '530px' : '260px', borderRadius: '4px', boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)', marginBottom: '10px', marginLeft: '10px' }}>
				{/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px', paddingLeft: '10px', borderBottom: '1px #dfdfdf solid', fontSize: '18px', fontWeight: 300, borderTopRightRadius: '4px', borderTopLeftRadius: '4px' }}> */}
					{/* <div>{title}</div> */}
				{/* </div> */}
				<div style={{ padding: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', color: '#ccc', fontWeight: 200 }}>
					No Data
				</div>
			</div>
		)
	}
	return (
		<div style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: isDouble ? 'calc(50% - 10px)' : 'calc(25% - 10px)', height: '300px', minWidth: isDouble ? '530px' : '260px', borderRadius: '4px', boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)', marginBottom: '10px', marginLeft: '10px' }}>
			{title && 
				<div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px', paddingLeft: '10px', fontSize: '18px', fontWeight: 300, borderTopRightRadius: '4px', borderTopLeftRadius: '4px' }}>
					<div>{title}</div>
				</div>
			}
			<div style={{position: 'absolute', top: '10px', right: '10px'}}>{toggle}</div>
			<div style={{ padding: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>{content}</div>
		</div>
	)
}

export default GenericCard